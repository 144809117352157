const confirmExit = () => {
	window.onbeforeunload = (event) => {
		const e = event || window.event;
		// Cancel the event
		e.preventDefault();
		if (e) {
			e.returnValue = ''; // Legacy method for cross browser support
		}
		return ''; // Legacy method for cross browser support
	};
};

export default confirmExit;