import axios from "axios";
import fetchHeaders from "./fetchHeaders";

const howIAm = async () => {
	const response = await axios.get(`${process.env.REACT_APP_API_HOST}/api/user/howiam`,
		{
			headers: fetchHeaders()
		})
	if (response.status !== 200 || response.status === 404) {
		console.log("no user");
		return null;
	}

	const json = await response.data;
	return json.data;
};

export default howIAm;