import React, { useEffect, useState } from "react";
import { Alert, Button } from "react-bootstrap";

const Categors = ({ xml, setSelectedCategor, setSelectedCategorKey, rates, categorStatus }) => {

	const [isComplete, setIsComplet] = useState(null);

	const selectCategor = (categor, key, isNotComplete) => {
		if (isNotComplete) {
			setSelectedCategor(categor);
			setSelectedCategorKey(key)
		}
	}
	const checkComplete = (noComplete) => {
		if (isComplete !== false && isComplete !== noComplete) {
			setIsComplet(noComplete);
		}
	}

	const hasComplete = (categor, key) => {
		const votedCount = (parseInt(rates[key + 1]) || 0);
		return votedCount >= categor.picture.length && categorStatus?.includes(key+1);
	}

	useEffect(() => {
		setIsComplet(null);
		checkComplete(false);
	}, [rates, categorStatus])
	return (
		<>
			{isComplete && <Alert>Minden kép ponzotása megtörtént. Köszönjük a részvételt!</Alert>}
			<p>
				<b>Kategóriák: </b>
			</p>
			<div>
				{xml && xml.categors.map((categor, key) => {
					const complete = hasComplete(categor, key);
					checkComplete(complete);
					return <>
						<Button
							variant={complete ? "text" : "primary"}
							className="mt-2"
							onClick={() => selectCategor(categor, key, !complete)}
							key={key}>
							{categor.name} ({(parseInt(rates[key + 1]) || 0)} kép zsűrizve a {categor.picture.length} képből) {!complete ? "zsűrizés indítása" : "ZSŰRIZÉS KÉSZ"}
						</Button><th /></>
				})}
			</div>
		</>
	)
};

export default Categors;