import { useEffect, useState } from "react";
import { Badge, Card, Container, Button, Form } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import Images from "./Images";
import xmlValidate from "../NewVote/xmlValidate";
import RealTimeFields from "./RealTimeFields";
import ClosedResult from "./VotePage/ClosedResult";
import fetchHeaders from "../../actions/fetchHeaders";
import axios from "axios";
import { toast } from "react-toastify";

const VotePage = () => {
	let { id } = useParams();
	if (!id) {
		window.location = "/";
	}

	const [vote, setVote] = useState(null);
	const [xml, setXml] = useState(null);
	const [editedZs, setEditedZs] = useState(null);
	const [editedZsName, setEditedZsName] = useState(null);
	const [editedZsEmail, setEditedZsEmail] = useState(null);

	const [isNewZs, setIsNewZs] = useState(false);
	const [newZsName, setNewZsName] = useState("");
	const [newZsEmail, setNewZsEmail] = useState("");

	const [newXml, setNewXml] = useState(null);
	const [validXML, setValidXML] = useState(null);
	const [xmlError, setXMLError] = useState(null);
	const [categories, setCategories] = useState(null);

	const [imagesCount, setImagesCount] = useState(null);

	const [closed, setClosed] = useState(false);
	const [allVoteCount, setAllVoteCount] = useState(0);
	const [simpleVoteComplete, setSimpleVoteComplete] = useState(false);



	useEffect(() => {
		axios.get(`${process.env.REACT_APP_API_HOST}/api/vote/${id}`,
			{
				headers: fetchHeaders()
			})
			.then(response => {
				if (response.status === 200) {
					return response.data;
				}
				window.location = "/"
				return false;
			})
			.then(data => setVote(data.data)).catch(e => {
				window.location = "/"
				return false;
			});


		axios.get(`${process.env.REACT_APP_API_HOST}/votes/${id}/${id}.xml`)
			.then(response => {
				if (response.status === 200) {
					setXml(response.data);
					return true;
				}
				else {
					alert("Hiba a képek betöltése során")
				}
				return false;
			}).catch(e => {
				console.log(e);
				alert("Hiba a képek betöltése során. Az XML nem elérhető el.")
			});
	}, []);


	useEffect(() => {
		if (vote && vote.vote_counts && imagesCount) {
			const maxVoteCount = imagesCount.reduce((a, b) => a + b, 0) * 2;
			const currentVoteCount = vote.vote_counts.map(count => parseInt(count.count)).reduce((a, b) => a + b, 0);
			setAllVoteCount(currentVoteCount);
			setSimpleVoteComplete(maxVoteCount <= currentVoteCount);

		}
	}, [imagesCount, vote])
	useEffect(() => {
		if (vote && vote.categors) {
			const categors = JSON.parse(vote.categors);
			setCategories(categors);
			if (categors && vote?.stat?.length === categors.length) {
				setClosed(true);
			}
			else {
				setClosed(false);
			}
		}
	}, [vote])
	useEffect(() => {
		if (xmlError && xmlError !== "") {
			alert(xmlError);
		}
	}, [xmlError])


	useEffect(() => {
		if (validXML) {
			const formData = new FormData();
			formData.append('xml', newXml);
			formData.append('voteID', id);

			const requestOptions = {
				headers: fetchHeaders("multipart/form-data")
			};
			axios.post(`${process.env.REACT_APP_API_HOST}/api/vote/updateXML`, formData, requestOptions)
				.then(response => {
					if (response === 200) {
						return response.data;
					}
					if (response.status === 401) {
						window.location = "/#/login"
					}
					return false;
					//throw new Error('Unathorized');
				})
				.then(data => {
					var reader = new FileReader();

					reader.onload = function (e) {
						var content = reader.result;
						setXml(content);
						setNewXml(null);
					}

					reader.readAsText(newXml);

					alert(data.messages);
				});
		}
	}, [validXML])


	const deleteConfirm = (user) => {
		if (window.confirm(`Biztos, hogy törlöd a(z) ${user.name} nevű zsűri tagot?`) === true) {
			const formData = new FormData();

			formData.append('userID', user.id);
			formData.append('voteID', id);
			axios.post(`${process.env.REACT_APP_API_HOST}/api/vote/zsuri/delete`, formData, {
				headers: fetchHeaders("multipart/form-data")
			})
				.then(response => {
					if (response.status === 200) {
						return response.data;
					};
					alert("Hiba a törlés során");
					if (response.status === 401) {
						window.location = "/#/login"
					}
				})
				.then(data => {
					setVote({ ...vote, users: data.data })
				});
		}
		return false;
	}
	const sendRealEmails = () => {
		const path = '/api/vote/sendRealMails';
		const formData = new FormData();

		formData.append('voteID', id);

		axios.post(`${process.env.REACT_APP_API_HOST}${path}`, formData, {
			headers: fetchHeaders("multipart/form-data")
		})
			.then(response => {
				if (response.status === 200) {
					return response.data;
				};
				toast.error("Hiba a levél küldés során");
				if (response.status === 401) {
					window.location = "/#/login"
				}
			})
			.then(data => {
				toast.info(data.messages)
				setVote({ ...vote, users: data.data })
			});

	}
	const sendMail = (zsID = null, all = true) => {

		const path = all ? '/api/vote/sendMails' : '/api/vote/sendMail';

		const formData = new FormData();

		if (!all && zsID !== null) {
			formData.append('userID', zsID);
		}
		formData.append('voteID', id);
		axios.post(`${process.env.REACT_APP_API_HOST}${path}`, formData, {
			headers: fetchHeaders("multipart/form-data")
		})
			.then(response => {
				if (response.status === 200) {
					return response.data;
				};
				alert("Hiba a levél küldés során");
				if (response.status === 401) {
					window.location = "/#/login"
				}
			})
			.then(data => {
				alert(data.messages)
				setVote({ ...vote, users: data.data })
			});
	}

	const zsEdit = (user) => {
		setEditedZs(user.id);
		setEditedZsEmail(user.email);
		setEditedZsName(user.name);
	}

	const saveZs = () => {
		const formData = new FormData();

		formData.append('userID', editedZs);
		formData.append('name', editedZsName);
		formData.append('email', editedZsEmail);
		formData.append('voteID', id);
		axios.post(`${process.env.REACT_APP_API_HOST}/api/vote/zsuri/update`, formData, {
			headers: fetchHeaders("multipart/form-data")
		})
			.then(response => {
				if (response.status === 200) {
					return response.data;
				};
				alert("Hiba a frissítés során");
				if (response.status === 401) {
					window.location = "/#/login"
				}
			})
			.then(data => {
				setVote({ ...vote, users: data.data })
				setEditedZs(null);
				setEditedZsEmail(null);
				setEditedZsName(null);
			});
	}
	const saveNewZs = () => {
		const formData = new FormData();

		formData.append('name', newZsName);
		formData.append('email', newZsEmail);
		formData.append('voteID', id);
		axios.post(`${process.env.REACT_APP_API_HOST}/api/vote/zsuri/new`, formData, {
			headers: fetchHeaders("multipart/form-data")
		})
			.then(response => {
				if (response.status === 200) {
					return response.data;
				};
				alert("Hiba a mentés során");
				if (response.status === 401) {
					window.location = "/#/login"
				}
			})
			.then(data => {
				setVote({ ...vote, users: data.data })
				setIsNewZs(false);
				setNewZsEmail("");
				setNewZsName("");
			});
	}
	const upload = (xml) => {
		setNewXml(xml);
		xmlValidate(xml, setValidXML, setXMLError, setCategories);
	}

	return (
		<div>
			{vote && <Container>
				<h3 className="fw-bold">{vote.name}
					{!closed && simpleVoteComplete && vote.realtime && <div className="float-right"><Link to={`/r/${vote.id}`}><Button>Élő zsűrizés indítása </Button></Link><Button className="ms-2" variant="secondary" onClick={sendRealEmails}>Elérés emailek kiküldése</Button></div>}
				</h3>
				<Badge pill bg="light" text="dark">
					Létrehozva: {vote.created_date}
				</Badge>
				<hr />
				{!closed && <p>
					<b>Kategóriák:</b> {JSON.parse(vote.categors).join(", ")}
				</p>}
				{closed && <ClosedResult categories={categories} voteID={vote.id} xml={xml} name={vote.name} />}
				<p>
					<b>Zsűrik: </b>
					{!closed && !isNewZs && <Button variant="primary" size="sm" className="float-right ms-2" onClick={() => setIsNewZs(true)}>Zsűritag hozzáadása</Button>}
					{!closed && <Button variant="secondary" size="sm" className="float-right" onClick={() => sendMail()}>Összes zsűri levél kiküldése</Button>}
				</p>
				<div className="zsuri-cards">
					{vote.users && vote.users.map((user, zsuriKey) => <Card key={zsuriKey} className="zsuri-card">
						{editedZs !== user.id && <>
							<Card.Title>
								{user.name} <Badge pill bg="info" color="white">({parseInt(user.emailsent) === 1 ? "Meghívó elküldve" : "Meghívó még nem lett elküldve"})</Badge>
							</Card.Title>
							<Card.Subtitle>{user.email} </Card.Subtitle>
							<Card.Body>
								{imagesCount && vote.vote_counts && categories && vote.vote_counts.filter(countData => countData.vote_rate_user_id === user.id).map(count => <div key={`count-${count.vote_rate_categor}`}>{categories[parseInt(count.vote_rate_categor) - 1]}: zsűrizve: {count.count}/{imagesCount[parseInt(count.vote_rate_categor) - 1] || ""}</div>)}
							</Card.Body>
							{!closed && <Card.Footer>
								<Button className="me-2" variant="primary" onClick={() => zsEdit(user)} >Szerkesztés</Button>
								<Button className="me-2" variant="secondary" onClick={() => sendMail(user.id, false)}>Email újra küldése</Button>
								<Button className="me-2" variant="danger" onClick={() => deleteConfirm(user)}>Törlés</Button>
							</Card.Footer>}
						</>}

						{editedZs === user.id && <>
							<Card.Title>
								<Form.Control type="text" defaultValue={user.name} onChange={(e) => setEditedZsName(e.target.value)} />
							</Card.Title>
							<Card.Subtitle>
								<Form.Control type="email" defaultValue={user.email} onChange={(e) => setEditedZsEmail(e.target.value)} />
							</Card.Subtitle>
							<Card.Footer>
								<Button variant="primary" onClick={saveZs} >Mentés</Button>
							</Card.Footer>
						</>}


					</Card>)}
					{(!closed && isNewZs) && <Card className="zsuri-card">
						<Card.Title>
							Új zsűri hozzádása
						</Card.Title>
						<Card.Subtitle>
							<Form.Control type="text" onChange={(e) => setNewZsName(e.target.value)} />
							<Form.Control type="email" onChange={(e) => setNewZsEmail(e.target.value)} />
						</Card.Subtitle>
						<Card.Footer>
							<Button variant="primary" onClick={() => { saveNewZs() }} >Mentés</Button>
							<Button variant="secondary" className="ms-2" onClick={() => { setIsNewZs(false); setNewZsEmail(""); setNewZsName("") }} >Mégsem</Button>
						</Card.Footer>
					</Card>}
				</div>
				<div className="images">
					<h5>
						Képek a feltöltött xml alapján
						{!closed && allVoteCount === 0 && <Button variant="primary" size="sm" className="float-right" onClick={() => window.document.querySelector("#newxml").click()}>XML állomány cserélje</Button>}

					</h5>
					<input type="file" style={{ display: "none" }} onChange={(e) => upload(e.target.files[0] || null)} id="newxml" />
					{xml && <Images xml={xml} setImagesCount={setImagesCount} />}
				</div>
				<div className="fields">
					<RealTimeFields vote={vote} setVote={setVote} closed={closed} />

				</div>
				<hr />
				{!closed && <Button variant="danger">Zsűrizés törlése</Button>}
			</Container>}

		</div>
	);
}

export default VotePage;
