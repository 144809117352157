import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
//import { useDrag } from 'react-dnd'
import { Draggable } from 'react-drag-and-drop';
import { useSelector } from "react-redux";


const Awards = ({ awardsList }) => {

	const userState = useSelector((state) => state.user.value)

	const awardValues = useSelector((state) => state.awards.value.values);

	const [currentAwards, setCurrentAwards] = useState([]);
	useEffect(() => {
		setCurrentAwards(awardValues.filter(award => award.hash === userState.hash));
	}, [awardValues])

	return <div className="award-container">

		{awardsList && awardsList.length > 0 && <div> <b>Kiosztható díjak:</b> {awardsList
			.map(field => {
				const buttonType = currentAwards.find(award => award.field_id === field.id) ? "btn-outline-secondary" : "btn-primary";
				return (<React.Fragment key={field.id}>
					{userState.icon !== "admin" && <Draggable type="award" data={field.id} className={`btn ${buttonType} btn-sm me-2`}>
						{field.label}
					</Draggable>
					}
					{userState.icon === "admin" && <button type="button" className="btn btn-light  btn-sm me-2" disabled>{field.label}</button>}
				</React.Fragment>);
			}
			)}<br />
			{userState.icon !== "admin" && <Form.Text className="text-muted">
				A kiosztandó díjat csak fogdmeg és húzd rá a kiválasztott képre. (drag n drop)
			</Form.Text>}
		</div>
		}
		{(!awardsList || awardsList.length === 0) && <div>
			Ebben a kategóriába nincsenek kiosztható díjak.
		</div>}
	</div>
};

export default Awards;