import Cookies from 'universal-cookie';
const cookies = new Cookies();

export default (contentType = "application/json") => {
	const header = {
	}
	const token = cookies.get("token");
	if (token) {
		return {
			'Content-Type': contentType,
			'Authorization': "Bearer " + token
		}
	};
	return header;
}