import React, { useState } from "react";
import { Button, Card, Modal } from "react-bootstrap";
import Lightbox from "yet-another-react-lightbox";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Captions from "yet-another-react-lightbox/plugins/captions";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails"
import "yet-another-react-lightbox/plugins/thumbnails.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import StarRatings from "react-star-ratings";



const AllImageModal = ({ show, setShow, images, rates }) => {
	const handleClose = () => setShow(false);
	const [imageIndex, setImageIndex] = useState(-1)
	return (<>

		<Modal
			fullscreen={true}
			className={`modal`}
			show={show} onHide={handleClose}
		>
			<Modal.Header closeButton>
				<Modal.Title>Kategória képeinek áttekintése</Modal.Title>
			</Modal.Header>

			<Modal.Body className="all-image-card-body">
				{images && images.map((image, index) => {
					const currentRate = rates.find(rate => rate.image == image.originalKey)?.rate || null
					return <Card className=" mb-4" onClick={() => setImageIndex(index)}>
						<LazyLoadImage
							src={image.thumb_url}
							alt={`${image.title} című kép`}
						/>
						{/* <Card.Img variant="top" style={{
							backgroundImage: `url("${image.thumb_url}")`
						}} /> */}
						<Card.Body>
							<Card.Title>{image.title}</Card.Title>
							{currentRate && <Card.Body><StarRatings
								key={`rate-${image.originalKey}`}
								rating={parseFloat(currentRate)}
								starRatedColor="darkorange"
								numberOfStars={10}
								isHalf={true}
								starDimension={'15px'}
								starSpacing={1}
								name='rating'
							/><br />{currentRate} csillagra értékelve</Card.Body>}
						</Card.Body>
					</Card>
				})}
			</Modal.Body>

			<Modal.Footer>
				<Button variant="primary" onClick={handleClose}>Áttekintés befejezése ( pontozás )</Button>
			</Modal.Footer>
		</Modal>
		{images && <Lightbox
			open={imageIndex > -1}
			close={() => { setImageIndex(-1) }}
			index={imageIndex}
			plugins={[Captions, Fullscreen, Zoom, Thumbnails]}
			slides={images.map(img => {
				return {
					src: img.url,
					title: img.title
				}
			})}
		/>}
	</>
	)
};

export default AllImageModal;