import { createSlice } from '@reduxjs/toolkit'

export const awardsSlice = createSlice({
	name: 'user',
	initialState: {
		value: {
			types: [],
			values: []
		}
	},
	reducers: {
		updateAwards: (state, action) => {
			state.value.types = action.payload
		},

		updateAwardsValues: (state, action) => {
			state.value.values = action.payload;
		},
		updateAwardValue: (state, action) => {
			const awardData = action.payload;

			if (awardData.id) {
				let awards = [...state.value.values];
				const awardKey = awards.findIndex(award => award.id == awardData.id);
				if (awards[awardKey]) {
					awards[awardKey].value = awardData.value;
					state.value.values = [...awards];
				}
				else {
					state.value.values = [...state.value.values, awardData];
				}
			}

		}
	},
})

export const { updateAwards, updateAwardsValues, updateAwardValue } = awardsSlice.actions

export default awardsSlice.reducer