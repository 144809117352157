import React, { useEffect, useState, useRef, useCallback } from 'react';
//import WebViewer from '@pdftron/pdfjs-express';

const Test = () => {
	const viewer = useRef(null);

	/* useEffect(() => {
		WebViewer(
			{
				//path: '/webviewer/lib',
				//initialDoc: '/TP.pdf',
			},
			viewer.current,
		).then((instance) => {
			console.log(instance)
		});
	}, []);
 */
	return (
		<div className="MyComponent">
			<div className="header">React sample</div>
			<div className="webviewer" ref={viewer}></div>
		</div>
	);
}

export default Test;