import axios from "axios";
import fetchHeaders from "./fetchHeaders";

const getAwardValues = async (voteID, categorKey, hash) => {
	const response = await axios.get(`${process.env.REACT_APP_API_HOST}/api/vote/awards/${voteID}/${categorKey}/${hash}`,
		{
			headers: fetchHeaders()
		});
	if (response.status !== 200 || response.status === 404) {
		console.log("no award or more");
		return null;
	}

	const text = await response.data;
	return text.data;

}

export default getAwardValues;