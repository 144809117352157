import axios from "axios";
import fetchHeaders from "./fetchHeaders";

const changeRateValue = async (value, id, hash, categories, voteID) => {
	const formData = new FormData();

	formData.append('hash', hash);
	formData.append("RID", id);
	formData.append('categories', categories + 1);
	formData.append('VID', voteID);
	formData.append('rate', value);

	const requestOptions = {
		headers: fetchHeaders("multipart/form-data"),
	};
	const response = await axios.post(`${process.env.REACT_APP_API_HOST}/api/vote/changevote`, formData, requestOptions);
	if (response.status === 404) {
		alert("Hiba a mentés során");
	}
	if (response.status === 500) {
		alert("Hiba a mentés során!");
	}
	if (response.status !== 200) {
		return false;
	}
	const data = response.data
	return data;
};

export default changeRateValue;