import React from "react";
import { Alert } from "react-bootstrap";
import { useSelector } from "react-redux";
import Info from "./Info";

const AwaitJury = () => {

	const voteState = useSelector((state) => state.vote.value);
	return (
		<>
			{voteState.stat.length === voteState.categors.length ? <Alert key="success" variant="success">
				Gratulálok, az összes kategória zsűrizve!
			</Alert> :
				<Alert key="info" variant="info">
					Várakozás, a kategória zsűrizés indítására
				</Alert>

			}
			<Info />
		</>

	)
};

export default AwaitJury;