import React from "react";

const AwardIcons = ({ label }) => {
	if (label === null) {
		return;
	}
	let icon = null;
	switch (label) {
		case "Első helyezet":
			icon = "award-i.svg"
			break;
		case "Második helyezet":
			icon = "award-ii.svg"
			break;
		case "Harmadik helyezet":
			icon = "award-iii.svg"
			break;

		default:
			icon = null;
			break;
	}
	return (<span className="badge text-bg-info">
		{icon && <img className="award-icon" src={`/assets/images/${icon}`} title={label} alt={`${label} ikon`} />}
		&nbsp;{label}
	</span>)
};

export default AwardIcons;