import axios from "axios";
import fetchHeaders from "./fetchHeaders";

const getVoteByID = async (voteID) => {
	const response = await axios.get(`${process.env.REACT_APP_API_HOST}/api/vote/${voteID}`,
		{
			headers: fetchHeaders()
		});
	if (response.status !== 200 || response.status === 404) {
		console.log("no vote");
		return null;
	}

	const text = await response.data;
	return text.data;

}

export default getVoteByID;