import axios from "axios";
import { XMLParser } from "fast-xml-parser";
import fetchHeaders from "./fetchHeaders";
const parser = new XMLParser();

const getXML = async (voteID) => {
	const response = await axios.get(`${process.env.REACT_APP_API_HOST}/votes/${voteID}/${voteID}.xml`);
	if (response.status === 200) {
		const xmlText = response.data;
		return parser.parse(xmlText).data || {};
	}
	else {
		alert("Hiba a képek betöltése során")
		window.location = "/"
		return false;
	}

}

export default getXML;