import React, { useEffect, useState } from "react";
import { Button, ButtonGroup, DropdownButton, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import saveField from "../../../actions/saveField";
import { setFieldValue } from "../../../redux/reducers/voteSlice";
import socket from "../api";

const VotingSettings = () => {

	const rules = useSelector((state) => state.rules.value);
	const voteState = useSelector((state) => state.vote.value)
	const [limit, setLimit] = useState(null);

	const dispatch = useDispatch()

	useEffect(() => {
		let limitField = voteState.fields.find(field => field.type === "limit" && field.categor == voteState.currentCategories);
		if (!limitField) {
			limitField = voteState.fields.find(field => field.type === "limit" && field.categor == -1);
		}
		if (limitField && limitField.values) {
			setLimit(limitField);
		}
	}, [voteState])

	//const voteState = useSelector((state) => state.vote.value);

	const checkedRule = (e) => {
		socket.emit("changeRules", { key: "enabledVoting", value: !rules.enabledVoting || false });
	}

	const changeLimit = async (e) => {
		const newLimit = parseInt(e.target.value);
		const fieldValue = { FID: limit.id, value: newLimit };
		dispatch(setFieldValue(fieldValue));
		socket.emit("changeField", fieldValue);

		const response = await saveField(voteState.id, { id: limit.id, values: newLimit });
		if (response !== false) {
			toast.info("A mező mentve");
		}

	}

	const addLimit = async () => {
		const newLimit = {
			type: "limit",
			title: "Limit",
			categor: voteState.currentCategories,
			values: 10
		}
		const response = await saveField(voteState.id, newLimit);
		if (response !== false) {
			const object = {
				id: response.data.id,
				categor: response.data.categorKey,
				label: response.data.title,
				type: response.data.type,
				values: newLimit.values
			}
			dispatch(setFieldValue(object));
			socket.emit("changeField", object);

		}
	}
	return (
		<>
			<DropdownButton
				className="ms-2"
				as={ButtonGroup}
				id={`dropdown-button-drop-start`}
				drop="start"
				variant="secondary"
				title={`Beállítások`}
			>
				<div className="settings-container">
					<Form.Group className="mb-7" controlId="enabledJuryChange">
						<Form.Check type="checkbox" onChange={checkedRule} checked={rules.enabledVoting || false} label="Zsűri módosítási jog" />
					</Form.Group>
					<hr />
					{limit && <Form.Group className="mb-7" controlId="setLimit">
						<Form.Label>Képek száma  (limit)</Form.Label>
						<Form.Control type="number" defaultValue={limit?.values ?? 0} onChange={changeLimit} />
					</Form.Group>}
					{!limit && <div>Nem lett limit létrehozva ehhez a kategóriához.<br /><Button onClick={addLimit} variant="info">Limit lérehozása</Button></div>}
				</div>
			</DropdownButton>
		</>
	)
};

export default VotingSettings;