import { createSlice } from '@reduxjs/toolkit'
import resultGurad from '../../actions/checkResult'
import socket from '../../Components/RealTime/api'

export const voteSlice = createSlice({
	name: 'vote',
	initialState: {
		value: {},
	},
	reducers: {
		setID: (state, action) => {
			state.value.id = action.payload
		},
		setName: (state, action) => {
			state.value.name = action.payload
		},
		setFields: (state, action) => {
			state.value.fields = action.payload
		},
		setFieldValue: (state, action) => {
			const { FID, value } = action.payload;
			const fieldKey = state.value.fields.findIndex(field => field.id === FID);
			if (fieldKey >= 0) {
				state.value.fields[fieldKey].values = value;
			}
			else {
				state.value.fields = [...state.value.fields, action.payload]
			}
		},
		addStat: (state, action) => {
			state.value.stat = [...state.value.stat, action.payload]
		},
		updateVote: (state, action) => {
			const voteData = action.payload;
			if (voteData && voteData.id) {
				resultGurad(voteData.id, voteData.categors, true);
			}
			state.value = voteData;
		},
		setCategories: (state, action) => {
			const categorKey = action.payload;
			socket.emit("selectcategor", categorKey);
			state.value.currentCategories = categorKey;
		},
		updateCategories: (state, action) => {
			state.value.currentCategories = action.payload
		}
	},
})

export const { setName, setCategories, setFields, updateVote, updateCategories, setFieldValue, addStat } = voteSlice.actions;

export const getVoteName = (state) => state.vote.value.name;
export const getVoteCategories = (state) => state.vote.value.categories;
export const getCategorImages = (state) => state.vote.value?.xml?.categors[state.vote.value.currentCategories || 0].picture;

export default voteSlice.reducer;