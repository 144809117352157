import axios from "axios";
import fetchHeaders from "./fetchHeaders";

const saveField = async (voteID, fieldData) => {
	const formData = new FormData();

	formData.append('VID', voteID);

	let key;
	for (key in fieldData) {
		formData.append(key, fieldData[key]);
	}

	const requestOptions = {
		headers: fetchHeaders("multipart/form-data")
	};
	const response = await axios.post(`${process.env.REACT_APP_API_HOST}/api/vote/fieldSave`, formData, requestOptions);
	if (response.status === 404) {
		alert("Hiba a mentés során");
	}
	if (response.status === 500) {
		alert("Hiba a mentés során!");
	}
	if (response.status !== 200) {
		return false;
	}
	const data = await response.data
	return data;
};

export default saveField;