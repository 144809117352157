import React from "react";
import { Alert, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setCategories } from "../../../redux/reducers/voteSlice";
import Info from "./Info";

const AwaitStart = () => {

	const voteState = useSelector((state) => state.vote.value);

	const dispatch = useDispatch();

	const isClosed = voteState.stat.length === voteState.categors.length

	return (<>
		<Alert key={isClosed ? "success" : "info"} variant={isClosed ? "success" : "info"}>
			{!isClosed ? "A zsűrizés indítása ha mindenki készen áll és minden zsűri jelen van!" :
				"A zsűrizés lezárult. Az összes kategória zsűrizve."}
			<br />
			{
				voteState.categors.map((categor, key) =>
					<div key={key}><hr />
						{(voteState.stat && voteState.stat.includes(key)) && < Button variant="outline-success" disabled>Lezárva: {categor}</Button>}
						{(!voteState.stat || !voteState.stat.includes(key)) && < Button onClick={() => dispatch(setCategories(key))}>Indítás: {categor}</Button>}
					</div>
				)}

		</Alert>
		<Info />
	</>
	)
};

export default AwaitStart;