import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import getVoteByHash from "../../actions/getVoteByHash";
import getVoteByID from "../../actions/getVoteByID";
import validateIsAdmin from "../../actions/validateIsAdmin";
import { Link } from "react-router-dom";

const Results = () => {
	let { voteID, hash } = useParams();
	if (!voteID) {
		window.location = "/";
	}
	const [vote, setVote] = useState(null);

	const init = async () => {
		let isAdmin = false;
		let user = null;
		let categors;
		if (hash && hash.length === 32) {
			//Hash-ből minden adat lekérése
			const voteAndUserData = await getVoteByHash(hash);
			if (voteAndUserData !== null) {
				categors = JSON.parse(voteAndUserData.categors);
				user = voteAndUserData.user;
				setVote({ ...voteAndUserData, categors: categors })
			}
		}

		if (user === null) {
			//Ellenőrizn, hogy admin e ebben a zsűrizésben
			isAdmin = await validateIsAdmin(voteID);
			//Az felhasználó adatainak lekérése
			if (isAdmin) {
				//Zsűrizés adatinak lekérése
				const voteData = await getVoteByID(voteID);
				categors = JSON.parse(voteData.categors);
				setVote({ ...voteData, categors: categors })
			}
			else {
				alert("Nincs ilyen zsűrizés")
				window.location = "/"
				return false;
			}
		}
	}

	useEffect(() => {
		init();
	}, [])


	return (
		<>
			{vote && <div className="container">
				<h1>{vote.name} páláyzat zsűrizése lezárult.</h1>
				<h2>Az eredmény megtekintése</h2>
				<h3>Kategóriák</h3>
				{vote.categors.map((categor, key) => <Link className="btn btn-primary" to={`/vote/result/${voteID}/${key}${hash ? "/" + hash : ""}`} key={`categor-${key}`}>{categor} kategória eredménye</Link>)}
			</div>}
		</>
	)
};

export default Results;