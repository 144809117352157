import React, { useEffect, useState } from "react";
import { Alert, Button, Card, ListGroup } from "react-bootstrap";
import StarRatings from "react-star-ratings";
import { SortNumericDown, SortNumericUpAlt } from 'react-bootstrap-icons';
import { imageOrderAndLimit } from "../utils/imageOrderAndLimits";
import VotingSettings from "../Voting/VotingSettings";
import { useDispatch, useSelector } from "react-redux";
import slugify from "slugify";

import { Droppable } from 'react-drag-and-drop';

import Awards from "../Voting/Awards";
import { updateAwardsValues, updateAwards, updateAwardValue } from "../../../redux/reducers/awardsSlice";
import getAwardValues from "../../../actions/getAwardValues";
import ImageAward from "./ImageAward";
import setAward from "../../../actions/setAward";
import socket from "../api";

import Lightbox from "yet-another-react-lightbox";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Captions from "yet-another-react-lightbox/plugins/captions";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import saveResult from "../../../actions/saveResult";
import { addStat, setCategories } from "../../../redux/reducers/voteSlice";
import closeCategor from "../../../actions/closeCategor";
import { toast } from "react-toastify";

const Images = ({ images, rates, change = null }) => {
	const dispatch = useDispatch()

	const [limit, setLimit] = useState(0);
	const [index, setIndex] = React.useState(-1);

	const userState = useSelector((state) => state.user.value);
	const voteState = useSelector((state) => state.vote.value);
	const awardValues = useSelector((state) => state.awards.value.values);

	const rules = useSelector((state) => state.rules.value);

	const isAdmin = userState.icon === "admin";

	const [lastImageRate, setLastImageRate] = useState(0);
	const [outImages, setOutImages] = useState(-1);
	const [imageReverse, setImageReverse] = useState(false);
	const [saveProcessed, setSaveProcessed] = useState(null);

	const [orderRates, setOrderRates] = useState({})

	const [awardsList, setAwardList] = useState(null);

	useEffect(() => {
		if (saveProcessed === true) {
			const content = document.querySelector(".voting-controller").innerHTML;
			saveResult(voteState.id, content, voteState.currentCategories);
			setSaveProcessed(false);
		}
		if (saveProcessed === false) {
			dispatch(setCategories(null));
		}

	}, [saveProcessed])

	useEffect(() => {
		if (rates) {
			const imageList = imageOrderAndLimit(rates, limit, imageReverse);
			setOrderRates(imageList)
		}
	}, [rates, imageReverse])

	useEffect(() => {
		if (!socket._callbacks["$awardChange"]) {
			socket.on("awardChange", (award) => {
				dispatch(updateAwardValue(award))
			});
		}
		if (!socket._callbacks["$categoriesClose"]) {
			socket.on("categoriesClose", (categor) => {
				toast.info(`A kategória zsűrizés befejezve.`);
				dispatch(addStat(categor))
			});
		}
	}, [])

	useEffect(() => {
		if (imageReverse) {
			setLastImageRate(orderRates.at(0)?.count || 0)
		}
		else {
			setLastImageRate(orderRates.length > 0 && orderRates.at(-1)?.count || 0)
		}
		const outImageCount = orderRates.length - limit;
		if (limit > 0) {
			setOutImages(outImageCount);
		}
		if (outImageCount > 0) {
			setImageReverse(true)
		}
		else {
			setImageReverse(false);
		}
	}, [limit, orderRates])


	const getAwards = async () => {
		const allAward = await getAwardValues(voteState.id, voteState.currentCategories, userState.hash);
		dispatch(updateAwardsValues(allAward));
	}

	useEffect(() => {
		console.log(awardValues)
	}, [awardValues])
	useEffect(() => {
		let limitField = voteState.fields.find(field => field.type === "limit" && field.categor == voteState.currentCategories);
		if (!limitField) {
			limitField = voteState.fields.find(field => field.type === "limit" && field.categor == -1);
		}
		if (limitField && limitField.values) {
			setLimit(parseInt(limitField.values))
		}
		let awardFields = voteState.fields.filter(field => field.type === "award" && field.categor == voteState.currentCategories);
		if (!awardFields) {
			awardFields = voteState.fields.filter(field => field.type === "award" && field.categor == -1);
		}
		if (awardFields) {
			setAwardList(awardFields);
			dispatch(updateAwards(awardFields));
		}
		getAwards();
	}, [voteState])

	const changeRating = (ratingPoint, rateID) => {
		change(ratingPoint, rateID)
	}

	const enabledChange = (rate) => {
		if (!isOut()) {
			return false;
		}
		if (isAdmin) {
			return false;
		}
		if (rules.enabledVoting && userState.hash === rate.user.hash) {
			return true;
		}
		return false;
	}

	const isOut = () => outImages > 0



	const handleDrop = async (data, event) => {
		const awardID = data.award;
		const imageID = event.currentTarget.getAttribute("data-key");
		const sendData = {
			field_id: awardID,
			value: imageID,
			categorKey: voteState.currentCategories,
			hash: userState.hash
		}
		const responseData = await setAward(voteState.id, sendData);
		if (responseData) {
			dispatch(updateAwardValue(responseData));
			socket.emit("setAward", responseData);
		}
	}

	const isEqualVoted = (key) => {
		const voteCount = rates.filter(rate => rate.image == key).length;
		const awards = awardValues
			.filter(awardValue => awardValue.value === key)
			.map(awardValue => awardsList && awardsList.length > 0 && awardsList?.find(award => award.id === awardValue.field_id)?.label | null)
		const isEqualAward = awards.every((val, i, arr) => val === arr[0]);
		if (isEqualAward && voteCount === awards.length) {
			return awards[0]
		}
		return false;
	}

	const closeCategorHandler = () => {
		if (window.confirm(`Biztos, hogy lezárod a kategória zsűrizését? A zárás után már nem lehet módosítani az eredményen.`) === true) {
			//document.querySelector(".controlls-btn").remove();
			//const content = document.querySelector(".voting-controller").innerHTML;
			const isClosed = closeCategor(voteState.id, voteState.currentCategories);
			if (isClosed) {
				socket.emit("closeCategor", voteState.currentCategories);
				dispatch(addStat(voteState.currentCategories))
				setSaveProcessed(true);
			}
			//console.log(content);
		}

	}

	return (<>
		{isOut() && <Alert variant="danger">
			Figyelem, a beállított képek száma {limit}. A listában viszont {outImages} képpel több szerepel.<br />

		</Alert>}
		<Card body className={"controlls" + (isOut() && !isAdmin ? " hide" : "")}>
			{!isOut() && <Awards awardsList={awardsList} />}
			{!saveProcessed && <div className="controlls-btn">
				{isAdmin && <Button variant="success" className="me-2" onClick={closeCategorHandler}>Kategória lezárása</Button>}
				{/* 				<Button variant="outline-primary" onClick={() => setImageReverse(!imageReverse)}>

					Sorrend&nbsp;
					{imageReverse && <SortNumericUpAlt />}
					{!imageReverse && <SortNumericDown />}
				</Button> */}
				{isAdmin && <VotingSettings />}
			</div>}
		</Card>
		<div className={`imageList${imageReverse ? " reverse" : ""}`}>
			{orderRates && orderRates.length > 0 && orderRates.map((currentRate, orderKey) => {
				//(img, key)
				const key = currentRate.imgKey;
				const img = images[key];
				if (!img) {
					return null;
				}
				let firstOut = limit > 0 && limit === orderKey ? " firstout" : "";
				if (imageReverse) {
					firstOut = (limit > 0 && orderRates.length - limit === orderKey) ? " firstout" : "";
				}
				const isAwarded = isEqualVoted(key);
				return <Droppable
					key={`dropable-${key}`}
					className="dropable-image"
					types={['award']}
					id={`image-${key}`}
					data-key={key}
					onDrop={handleDrop}
					style={{ margin: "10px", width: "23%" }}
				> <Card
					key={img.id}
					className={`image ${isAwarded ? "awarded " + slugify(isAwarded, { lower: true, trim: true }) : ""} image-${orderKey} ${limit > 0 && limit < orderRates.length && lastImageRate == currentRate.count ? "outoflimit" : ""} ${firstOut}`}
				>
						{isAwarded && <p className="flag is-offer">
							<span className="flag-text">{isAwarded}</span>
						</p>}

						<div className="image-container card-img-top">
							<Card.Img variant="top" src={`${img.url}`} onClick={() => setIndex(orderKey)} />
						</div>
						<Card.Body>
							{saveProcessed === true && img?.author?.full_name && <>
								<Card.Title>{img.author.full_name}</Card.Title>
								<Card.Subtitle className="text-center mb-4">{img.title} ({currentRate.count})</Card.Subtitle>
							</>}
							{(saveProcessed !== true || !img?.author?.full_name) && <Card.Title>{img.title} ({currentRate.count})</Card.Title>}
							<ListGroup variant="flush">
								{rates.filter(rate => rate.image == key).map(rate =>
									<ListGroup.Item key={rate.id}>
										<h6>{rate.user.name} <ImageAward rate={rate} /></h6>
										<StarRatings
											rating={parseInt(rate.rate)}
											starRatedColor="darkorange"
											starHoverColor="orange"
											changeRating={enabledChange(rate) ? changeRating : null}
											numberOfStars={10}
											isHalf={true}
											starDimension={'20px'}
											starSpacing={"0px"}
											name={rate.id}
										/>
									</ListGroup.Item>)}

							</ListGroup>
						</Card.Body>
					</Card>
				</Droppable>
			}
			)}
		</div>
		{orderRates && orderRates.length > 0 && <Lightbox
			open={index && index >= 0}
			index={index}
			close={() => setIndex(-1)}
			plugins={[Captions, Fullscreen, Zoom]}
			slides={orderRates.map((currentRate) => {
				const key = currentRate.imgKey;
				const img = images[key];
				if (img) {
					return {
						src: `${img.url}`,
						title: img.title
					}
				}
			})}
		/>}
	</>
	)
};

export default Images;