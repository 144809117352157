import React, { useEffect } from "react";
import { Badge, Card } from "react-bootstrap";
import { useSelector } from "react-redux";

const Info = () => {
	const voteState = useSelector((state) => state.vote.value);


	return (<div className="info">
		<hr />
		<h4>Pályázati információk</h4>
		<hr />
		<h5>Pályázat neve: {voteState.name}</h5>
		<h6>Zsűri tagok: {voteState.jury.join(", ") || ""}</h6>

		<h6 className="mt-3 mb-3">Kategóriák</h6>
		{voteState.xml && voteState.xml.categors && voteState.xml.categors.map((categor, key) => {

			//díjjak és limitek filter categor key or -1
			//voteState.fields
			const currentFields = voteState.fields.filter(field => field.categor == key || field.categor == -1);
			currentFields.sort((a, b) => { return parseInt(a.categor) - parseInt(b.categor) });
			const currentLimit = currentFields.find(field => field.type === "limit");
			const currentAward = currentFields.filter(field => field.type === "award");
			return (<Card className="mb-4" key={`award-${key}`}>
				<Card.Header>
					{categor.name}
					{(voteState.stat && voteState.stat.includes(key)) && <Badge bg="success" className="ms-2">Zsűrizve</Badge>}
				</Card.Header>
				<Card.Body>
					<label>Képek száma: </label> <b> {categor.picture.length}</b><br />
					{currentLimit && <><label>Tovább jutó képek száma: </label> <b> {currentLimit.values}db</b><br /></>}
					{currentAward && currentAward.length > 0 && <><label>Kiosztható díjak: </label> {currentAward.map((award, awardKey) => <Badge key={`award-${awardKey}-${award.label}`} className="ms-2" bg="info">{award.label}</Badge>)}</>}

				</Card.Body>
			</Card>)
		})}

	</div>
	)
};

export default Info;