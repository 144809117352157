import { useEffect, useState } from "react";
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import Badge from 'react-bootstrap/Badge';
import { Button, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import Cookies from 'universal-cookie';
import fetchHeaders from "../../actions/fetchHeaders";
import axios from "../../actions/axiosConfig";
//import axios from "axios";

const cookies = new Cookies();

const VoteList = () => {

	const [votes, setVotes] = useState(null);

	useEffect(() => {
		axios.get(`${process.env.REACT_APP_API_HOST}/api/vote/list`, {
			headers: fetchHeaders()
		})
			.then(response => {
				setVotes(response.data.data)

			})
			.catch(e => {
				if (e.response.status === 401) {
					const cookie = cookies.get("hash");
					if (cookie) {
						window.location = "/#/v/" + cookie;
					}
					else {
						window.location = "/#/login"
					}
				}
				//throw new Error('Unathorized');
			})
		//.then()
	}, []);

	return (
		<Container>
			{votes && <Card>
				<Card.Header>Létrehozott zsűrizések</Card.Header>
				<Card.Body>
					<Card.Title></Card.Title>
					<ListGroup variant="flush">
						{votes.length <= 0 && <div>Nincs zsűrizés létrehozva. Hozd létre az első zsűrizésed.<br /><Link className="btn btn-primary" to="/new">Új zsűrizés létrehozása</Link></div>}
						{votes.length > 0 && votes.map(vote => <ListGroup.Item key={vote.id}>
							<Button className="float-right" onClick={() => window.location.hash = "#/vote/" + vote.id} variant="primary">Részletek megtekintése</Button>
							<div className="ms-2 me-auto">
								<div className="fw-bold"><Link to={`vote/${vote.id}`}>{vote.name}</Link></div>
								Kategóriák: {JSON.parse(vote.categors).join(", ")}
							</div>
							<Badge pill bg="light" text="dark">
								Létrehozva: {vote.created_date}
							</Badge>
						</ListGroup.Item>)}
					</ListGroup>
				</Card.Body>
			</Card>}

		</Container>
	);
}

export default VoteList;
