import axios from "axios";
import fetchHeaders from "./fetchHeaders";

const getVoteByHash = async (hash) => {
	const response = await axios.get(`${process.env.REACT_APP_API_HOST}/api/vote/h/${hash}`,
		{
			headers: fetchHeaders()
		});
	if (response.status !== 200 || response.status === 404) {
		console.log("no hash");
		return null;
	}

	const text = await response.data;
	return text.data;

}

export default getVoteByHash;