import React, { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import ReactLoading from 'react-loading';
import Cookies from 'universal-cookie';
import md5 from 'md5';

import howIAm from "../../actions/howIAm";
import getNewTokenByHash from "../../actions/getNewTokenByHash";
import getVoteByHash from "../../actions/getVoteByHash";
import validateIsAdmin from "../../actions/validateIsAdmin";
import getVoteByID from "../../actions/getVoteByID";
import getVoteFields from "../../actions/getVoteFields";

import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "../../redux/reducers/userSlice";
import { updateVote, setFields } from "../../redux/reducers/voteSlice";
import getXML from "../../actions/getXml";
import confirmExit from "../../actions/confirmExit";

const cookies = new Cookies();

const RealTimeStart = () => {
	const userState = useSelector((state) => state.user.value)
	const voteState = useSelector((state) => state.vote.value);
	const dispatch = useDispatch()

	const { identify } = useParams();

	const checkEnded = (voteID, stat, categories, hash = null) => {
		if (stat.length === categories.length) {
			window.location.href = `/#/vote/${voteID}/result${(hash ? `/${hash}` : "")} `;
		}
	}

	useEffect(() => {
		async function validate() {
			let voteID = null;
			let name = "";
			let user = null;
			let categors = [];
			let hash = "";
			let stat = [];
			let jury = [];
			//let categorRates = {};
			let isAdmin = false;
			if (identify.length === 32) {
				//Hash-ből minden adat lekérése
				await getNewTokenByHash(identify);
				const voteAndUserData = await getVoteByHash(identify);
				if (voteAndUserData !== null) {
					voteID = voteAndUserData.id;
					name = voteAndUserData.name;
					user = voteAndUserData.user;
					stat = voteAndUserData.stat;
					jury = voteAndUserData.jury;
					categors = JSON.parse(voteAndUserData.categors);
					hash = identify;
					cookies.set('hash', hash, { path: '/' });
					checkEnded(voteID, stat, categors, hash)
					//categorRates = voteAndUserData.rates;
				}
			}

			if (user === null) {
				//Ellenőrizn, hogy admin e ebben a zsűrizésben
				isAdmin = await validateIsAdmin(identify);
				//Az felhasználó adatainak lekérése
				const userResponse = await howIAm();
				if (isAdmin && userResponse !== null) {
					user = userResponse;
					//Zsűrizés adatinak lekérése
					const voteData = await getVoteByID(identify);
					name = voteData.name;
					categors = JSON.parse(voteData.categors);
					voteID = identify;
					hash = md5(userResponse.name + userResponse.email);
					stat = voteData.stat;
					jury = voteData.jury;
					checkEnded(voteID, stat, categors)
				}
				else {
					alert("Nincs ilyen zsűrizés")
					window.location = "/"
					return false;
				}
				cookies.remove('hash');
			}
			const xmlData = await getXML(voteID);

			const fields = await getVoteFields(voteID, hash);
			dispatch(setFields(fields));

			dispatch(updateVote({
				id: voteID,
				name: name,
				categors: categors,
				xml: xmlData,
				fields: fields,
				stat: stat,
				jury: jury
			}));
			dispatch(updateUser({ ...user, hash: hash, icon: (isAdmin ? "admin" : "user") }))
		};
		validate();
		confirmExit()

	}, [])

	const isEmpty = (object) => Object.keys(object).length === 0
	// voteState.categors .stat
	return (
		<Container>

			{(isEmpty(userState) || isEmpty(voteState)) && <ReactLoading type={"spin"} color={"blue"} height={'20%'} width={'20%'} />}
			{(!isEmpty(userState) || !isEmpty(voteState)) && <><h3>
				Üdvözöllek a(z) {voteState.name} című pályázat élő zsűrizésében </h3>

				<Link to="/r/s/start" >Belépés az zsűrizésre mint <b>{userState.name}</b></Link>
			</>
			}
		</Container >
	)
};

export default RealTimeStart;