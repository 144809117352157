import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import socket from './api';
import confirmExit from "../../actions/confirmExit";
import { updateJury } from "../../redux/reducers/onlineJurySlice";
import UserList from "./UserList";
import { Col, Container, Row } from "react-bootstrap";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import { setFieldValue, updateCategories } from "../../redux/reducers/voteSlice";
import { toast } from "react-toastify";
import VotingContainer from "./Voting/VotingContainer";
import { updateRules } from "../../redux/reducers/votingRules";

const RealTimeVoting = () => {
	const [isConnected, setIsConnected] = useState(false);

	const cookies = new Cookies();

	const dispatch = useDispatch()
	const navigate = useNavigate();

	const voteState = useSelector((state) => state.vote.value);
	const userState = useSelector((state) => state.user.value);

	const isEmpty = (object) => Object.keys(object).length === 0

	useEffect(() => {
		confirmExit()
	}, [])

	useEffect(() => {
		if (isEmpty(voteState) || isEmpty(userState)) {
			const hash = cookies.get('hash');
			if (hash) {
				navigate("/r/" + hash);
			}
			else {
				window.location = "/";
			}
		}
		if (socket.auth === undefined || !socket.auth.username) {

			socket.auth = { "username": userState.name, "uid": userState.hash, room: voteState.id, icon: userState.icon };
			socket.connect();
			socket.emit("startConnect", true);
			socket.on("isConnected", () => {
				setIsConnected(true);
			})

			if (!socket._callbacks["$users"]) {
				socket.on("users", (u) => {
					const userList = u.sort((a, b) => {
						if (a.self) return -1;
						if (b.self) return 1;
						if (a.username < b.username) return -1;
						return a.username > b.username ? 1 : 0;
					});
					dispatch(updateJury(userList))
				});
			}
			if (!socket._callbacks["$categorselected"]) {
				socket.on("categorselected", (key) => {
					if (key === "null") {
						key = null;
					}
					if (key !== null) {
						toast.info(`${key + 1}. kategória zsürézése elindítva`);
					}
					dispatch(updateCategories(key));
				})
			}
			if (!socket._callbacks["$updateRules"]) {
				socket.on("updateRules", (newRules) => {
					dispatch(updateRules(newRules))
				});
			}
			if (!socket._callbacks["$updateField"]) {
				socket.on("updateField", (newField) => {
					dispatch(setFieldValue(newField));
				});
			}
		}
	}, [socket]);

	return (
		<Container fluid>
			{isConnected &&
				<Row>
					<UserList hash="" />
					<Col xs="10" className="voting-controller">
						<VotingContainer />
					</Col>
				</Row >
			}
		</Container >
	)
};

export default RealTimeVoting;