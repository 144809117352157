import { XMLParser } from "fast-xml-parser";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import slugify from 'slugify';

import * as FileSave from "file-saver";
//import XLSX from 'sheetjs-style';
import * as XLSX from 'xlsx/xlsx.mjs';
import { toast } from "react-toastify";
import getAwardValues from "../../../actions/getAwardValues";
import axios from "axios";
import fetchHeaders from "../../../actions/fetchHeaders";


const CSVExport = ({ xml, rates, name, voteID }) => {

	const [categors, setCategors] = useState(null);
	const [fields, setFields] = useState({ limit: [], award: [], custom: [] });

	const header = ["Kategória", "Szerző", "Felhasználó név", "Emailcím", "Kép címe", "Kép url", "Kis kép url", "Helyezés", "Pont"];

	const getData = async (categor = null) => {
		const parser = new XMLParser();
		try {
			let jsonObj = parser.parse(xml)
			const categories = jsonObj?.data?.categors || [];

			const exportData = [header];
			let categorKey;
			for (categorKey in categories) {
				if (categor !== null && categor != categorKey) {
					continue;
				}
				const allAward = await getAwardValues(voteID, categorKey, "f2s5zh43ldk32K2kj234K");
				//const categorAwardFields = fields.award.filter(awardType => awardType.categor == categorKey || awardType.categor == -1);
				const awardWithLabel = allAward.map(award => {
					const awardName = fields.award.find(awardInfo => award.field_id == awardInfo.id)?.label ?? "Ismeretlen mező"
					return { ...award, label: awardName };
				})

				const filteredArray = awardWithLabel.filter((item, index, self) =>
					index === self.findIndex(
						(t) => t.key === item.key && t.value === item.value
					)
				);


				const currentCategor = categories[categorKey];
				const imageData = currentCategor.picture.map((picture, pictureKey) => [
					currentCategor.name,
					picture?.author?.full_name ?? "Nincs szerző",
					picture?.author?.user_name ?? "Nincs szerző",
					picture?.author?.email ?? "Nincs email cím",
					picture?.title ?? "Nincs cím",
					picture?.url ?? "Nincs kép",
					picture?.thumb ?? "Nincs kis kép",
					filteredArray.find(award => award.value == pictureKey)?.label ?? ""
				])
				imageData.map((image, imageKey) => {
					const categorListKey = parseInt(categorKey) + 1;
					const currentRate = rates[categorKey].filter(rate => parseInt(rate.categor) === categorListKey && parseInt(rate.image) === imageKey).reduce((a, b) => a + parseInt(b.rate), 0);
					image.push(currentRate ?? 0);

					exportData.push([...image]);
					return image.slice(1);
				})
			}
			return exportData;
		}
		catch (e) {
			console.log(e);
			toast.error("Hiba a csv előállítása során")
			return null;
		}
	}
	const getSlugifyedName = (name) => slugify(name, { remove: undefined, lower: true, strict: true, locale: 'hu', trim: true })
	const getCSV = async (categor = null) => {
		const slugiName = getSlugifyedName(name);
		let fileName = `${slugiName}-zsurizes-eredmenyek.csv`;
		if (categor !== null && categors[categor]) {
			const categorName = getSlugifyedName(categors[categor]);
			fileName = `${slugiName}-${categorName}-zsurizes-eredmenyek.csv`
		}

		const exportedData = await getData(categor);
		if (exportedData === null) {
			return;
		}
		let csvContent = "data:text/csv;charset=utf-8,"
			+ exportedData.map(e => e.join(";")).join("\n");
		var encodedUri = encodeURI(csvContent);
		window.saveAs(encodedUri, fileName)
	}

	const getXSLX = async () => {

		const wb = XLSX.utils.book_new();

		var frontSheet = XLSX.utils.aoa_to_sheet([
			[`Zsűrizési eredmények`],
			["Pályázat neve", name],
			["Kategóriák"],
			...categors.map(categorName => ["", categorName])
		]);
		XLSX.utils.book_append_sheet(wb, frontSheet, "Összefoglaló");

		for (let categorKey in categors) {
			const categorName = categors[categorKey];
			const sheetName = getSlugifyedName(categorName.length > 30 ? categorName.slice(0, 30) : categorName);
			const exportedData = await getData(categorKey);
			const ws = await XLSX.utils.aoa_to_sheet(exportedData);
			await XLSX.utils.book_append_sheet(wb, ws, sheetName, true);
		};

		if (!wb) return;

		const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet charset=utf-8";
		const fileExtension = ".xlsx";
		const excelBuffer = await XLSX.write(wb, { bookType: "xlsx", type: 'array' });
		const dataBlob = new Blob([excelBuffer], { type: fileType });

		FileSave.saveAs(dataBlob, getSlugifyedName(name) + "-zsurizes-eredmenyek" + fileExtension);
	}

	useEffect(() => {
		const parser = new XMLParser();
		try {
			let jsonObj = parser.parse(xml)
			const categories = jsonObj?.data?.categors.map(e => e.name) || [];
			if (categories !== []) {
				setCategors(categories);
			}
		}
		catch (e) {
			console.log(e);
			toast.error("Hiba az xml betöltése során")
			return;
		}
	}, [xml])

	useEffect(() => {
		axios.get(`${process.env.REACT_APP_API_HOST}/api/vote/plus/${voteID}/f2s5zh43ldk32K2kj234K`,
			{
				headers: fetchHeaders()
			})
			.then(response => {
				if (response.status === 200) {
					return response.data;
				}
				alert("Hiba az egyedi mezők betöltése során. Póbáld meg késöbb")
			})
			.then(data => {
				const fieldList = data.data;
				if (fieldList) {
					setFields({
						limit: fieldList.filter(field => field.type === "limit"),
						award: fieldList.filter(field => field.type === "award"),
						custom: fieldList.filter(field => field.type === "custom")
					})
				}

			});

	}, [voteID])

	return (
		<>
			{categors && categors.length > 0 && <div className="mt-4 ">
				<h5>CSV/XLSX letöltése</h5>

				<Button className="me-2 mb-2" variant="info" onClick={() => getCSV()}>Összes kategória</Button>
				{categors.map((categor, key) => <Button className="me-2 mb-2" variant="info" onClick={() => { getCSV(key) }}>{categor} kategória</Button>)}
				<Button onClick={getXSLX}>XLSX letöltése (excel)</Button>
			</div>
			}
		</>
	)
};

export default CSVExport;