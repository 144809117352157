import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AwaitStart from "../Await/AwaitStart";
import AwaitJury from "../Await/AwaitJury";
import Images from "../ListOfImages/Images";
import getCategorAllRates from "../../../actions/getCategorAllRates";
import changeRateValue from "../../../actions/changeRateValue";
import socket from "../api";
import { toast } from "react-toastify";

const VotingContainer = () => {

	//const limit = 13;

	const userState = useSelector((state) => state.user.value);
	const voteState = useSelector((state) => state.vote.value);

	const [images, setImages] = useState(null);
	const [rates, setRates] = useState(null);


	const getAndSetRates = async () => {
		const ratesResponse = await getCategorAllRates(voteState.id, voteState.currentCategories + 1, userState.hash);
		setRates(ratesResponse);
	}

	useEffect(() => {
		if (!socket._callbacks["$getyourrates"]) {
			socket.on("getyourrates", (key) => {
				getAndSetRates();
				toast.info(`${key.editor} módosította a ${key.title} című kép pontszámát!`);
			});
		}
	}, [])

	useEffect(() => {
		const categorImages = voteState.xml?.categors[voteState.currentCategories || 0]?.picture || null
		if (categorImages) {
			setImages(categorImages);
		}
		getAndSetRates();
	}, [voteState])

	if (!voteState || voteState.currentCategories === undefined || voteState.currentCategories === null) {
		return (
			<>
				{userState.icon === "admin" && <AwaitStart />}
				{userState.icon !== "admin" && <AwaitJury />}
			</>
		)
	}
	const changeRate = async (value, id) => {
		//value, id, hash, categories, voteID
		const response = await changeRateValue(value, id, userState.hash, voteState.currentCategories, voteState.id);
		if (response) {
			const imgTitle = images[response.data.vote_rate_img_key].title || "Ismeretlen";
			socket.emit("isnewrate", { title: imgTitle, editor: userState.name });

			getAndSetRates();
			toast.info(`A ${imgTitle} című kép pontszáma módosítva!`);
		}
	}


	return (<>
		{images &&
			<Images
				images={images}
				rates={rates}
				//limit={limit}
				hash={userState.hash}
				change={changeRate}
				isAdmin={userState.icon === "admin"} />
		}
	</>
	)



};

export default VotingContainer;