import React, { useContext } from "react";
import { Button, Container, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Logo } from "../../Icons";

import "./Header.scss";
import { useHeader } from "../../contexts/headerContext";


const Header = () => {  
	const { headerOptions } = useHeader();

  return (
      <Navbar bg="light" expand="lg" className={`header ${headerOptions.button ? "button" : "simple"}`}>
        <Container fluid={headerOptions.button}>
          {headerOptions.button && (
            <Button variant="outline" onClick={headerOptions.buttonHandler}>
              {" "}
              &#8592; Vissza a kategóriákhoz
            </Button>
          )}
          {headerOptions.isLink && (
            <Navbar.Brand className="header__brand">
              <Link className="header__link" to="/">
                <Logo className="header__logo" /> {headerOptions.brandText}
              </Link>
            </Navbar.Brand>
          )}
          {!headerOptions.isLink && <Navbar.Brand>{headerOptions.brandText}</Navbar.Brand>}
          {headerOptions.text && (
            <div style={{ fontSize: "90%" }} className="mr-auto">
              {headerOptions.text}
            </div>
          )}
        </Container>
      </Navbar>
  );
};

export default Header;