import { createSlice } from '@reduxjs/toolkit'

export const onlineJurySlice = createSlice({
	name: 'user',
	initialState: {
		value: [],
	},
	reducers: {
		updateJury: (state, action) => {
			state.value = action.payload
		},
	},
})

export const { updateJury } = onlineJurySlice.actions

export default onlineJurySlice.reducer