function hashObj(obj) {
	let str = JSON.stringify(obj);
	let hash = 0;
	if (str.length === 0) {
		return hash;
	}
	for (let i = 0; i < str.length; i++) {
		let char = str.charCodeAt(i);
		hash = ((hash << 5) - hash) + char;
		hash = hash & hash; // Konvertálás 32-bites egész számmá
	}
	return hash;
}

function sortImages(images) {
	const imageList = images.map((image, key) => { return { ...image, originalKey: key } });
	let mix = imageList.slice();
	mix.sort((a, b) => hashObj(a) - hashObj(b));
	return mix;
}

function hashSortImages(images) {
	return sortImages(images);
}

export default hashSortImages