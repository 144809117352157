import React, { createContext, useState } from 'react';
import _ from 'lodash';

const headerContext = createContext(null);
const defaultValue = {
	text: null,
	isLink: true,
	brandText: "Zsűriztető",
	button: false,
	buttonHandler: null,
  }

export const HeaderProvider = ({ children }) => {
	const [headerOptions, setHeaderOptions] = useState(defaultValue);

	const updateHeaderOptions = (newOptions) => {
		const updatedOptions = { ...headerOptions, ...newOptions }
		if (!_.isEqual(updatedOptions, headerOptions)) {
			setHeaderOptions(updatedOptions)
		}
	}

	const resetHeader = () => {
		if (!_.isEqual(headerOptions, defaultValue)) {
			setHeaderOptions(defaultValue)
		}
	}

	return (<headerContext.Provider value={{ headerOptions, updateHeaderOptions, resetHeader }} >
		{children}
	</headerContext.Provider >)
}

export const useHeader = () => {
	const ctx = React.useContext(headerContext);
	return ctx;
}