import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Cookies from 'universal-cookie';
/* import Header, {HeaderContext} from "../Header/Header"; */
import { XMLParser } from "fast-xml-parser";
import Categors from "./Voting/Categors";
import StarRating from "./Voting/StarRating";
import fetchHeaders from "../../actions/fetchHeaders";
//import { HeaderContext } from "../Header/HeaderContext";
import getNewTokenByHash from "../../actions/getNewTokenByHash";
import axios from "axios";
import { useHeader } from "../../contexts/headerContext";
import getVoteCategoriesStatus from "../../actions/getVoteCateroiesStatus";
import { useDispatch} from "react-redux";
import { updateUser } from "../../redux/reducers/userSlice";

const parser = new XMLParser();

const cookies = new Cookies();

const Voting = () => {
	const { updateHeaderOptions, resetHeader } = useHeader();

	const dispatch = useDispatch()

	let { hash } = useParams();
	if (!hash) {
		alert("NO HASH")
		window.location = "/";
		cookies.remove('hash');
	}
	else {
		cookies.set('hash', hash, { path: '/' });
	}

	const [vote, setVote] = useState(null);
	const [xml, setXml] = useState(null);
	const [selectedCategor, setSelectedCategor] = useState(null);
	const [selectedCategorKey, setSelectedCategorKey] = useState(null);
	const [categorStatus, setCategorStatus] = useState(null);
	const [showAllImage, setShowAllImage] = useState(true);
	

	async function validate() {
		await getNewTokenByHash(hash);
		axios.get(`${process.env.REACT_APP_API_HOST}/api/vote/h/${hash}`,
			{
				headers: fetchHeaders()
			})
			.then(response => {
				if (response.status === 200) {
					return response.data;
				}
				if (response.status === 404) {
					alert("Nincs ilyen zsűrizés");
				}
				window.location = "/"
				return false;
			})
			.then(data => {
				dispatch(updateUser({ ...data.data.user, hash: hash, icon: "user" }))
				setVote(data.data)
			});
	}

	useEffect(() => {
		validate();
	}, []);

	const resetCategor = () => {
		validate()
		setSelectedCategor(null);
		setSelectedCategorKey(null);
		setShowAllImage(true);
	}

	useEffect(() => {
		if (vote) {
			axios.get(`${process.env.REACT_APP_API_HOST}/votes/${vote.id}/${vote.id}.xml`)
				.then(async (response) => {
					if (response.status === 200) {
						const xmlText = response.data;
						setXml(parser.parse(xmlText).data || {});
						return true;
					}
					else {
						alert("Hiba a képek betöltése során")
					}
					return false;
				})
			getVoteCategoriesStatus(vote.id, vote.user.id).then((data) => {
				setCategorStatus(data);
			})
		}
	}, [vote])

	useEffect(() => {
		if (selectedCategor && vote) {
			updateHeaderOptions({
				text: `Belépve: ${vote.user.name} [${vote.user.email}]`,
				isLink:false,
				brandText:`${vote.name} zsűrizése ${selectedCategor ? ` => ${selectedCategor.name} kategória` : ""}`,
				button:!!selectedCategor,
				buttonHandler:resetCategor
			})
		}
		else if (vote) {
			updateHeaderOptions({
				text: `Belépve: ${vote.user.name} [${vote.user.email}]`,
				isLink:false,
				brandText:`${vote.name} zsűrizése`,
				button:false,
				buttonHandler:null
			})
		}
		else {
			resetHeader();
		}
	}, [selectedCategor, vote])


	return (<>
		{vote && <div>
			<Container className="picture-voting-container">
				{!selectedCategor && <Categors 
					xml={xml} 
					setSelectedCategor={setSelectedCategor} 
					setSelectedCategorKey={setSelectedCategorKey} 
					rates={vote.rates}
					categorStatus={categorStatus}
				/>}
				{selectedCategor && <>
					<StarRating
						selectedCategor={selectedCategor}
						selectedCategorKey={selectedCategorKey + 1}
						voteID={vote.id}
						userID={vote.user.id}
						finishHandler={resetCategor}
						showAllImage={showAllImage}
						setShowAllImage={setShowAllImage}
						categorStatus={categorStatus}
					/>
				</>}

			</Container>
		</div>}
	</>
	)

};

export default Voting;