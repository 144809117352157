import React from "react";
import { useSelector } from "react-redux";
import AwardIcons from "./AwardIcons";


const ImageAward = ({ rate }) => {
	const awardValues = useSelector((state) => state.awards.value.values);
	const awardTypes = useSelector((state) => state.awards.value.types);
	return (
		<>
			{awardValues && awardValues
				.filter(awardValue => awardValue.hash === rate.user.hash && awardValue.value === rate.image)
				.map(award => <span className="award" key={`award-${award.field_id}`}>
					<AwardIcons label={awardTypes.find(type => type.id === award.field_id)?.label || null} />
				</span>)}
		</>
	)
};

export default ImageAward;