import axios from "axios";
import fetchHeaders from "./fetchHeaders";

const validateIsAdmin = async (voteID) => {
	const response = await axios.get(`${process.env.REACT_APP_API_HOST}/api/vote/v/iav/${voteID}`,
		{
			headers: fetchHeaders()
		});

	if (response.status !== 200 || response.status === 404) {
		return false;
	}
	return true;
}

export default validateIsAdmin;