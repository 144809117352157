import React, { useEffect, useState } from "react";
import { XMLParser } from 'fast-xml-parser';
import { Accordion, Card } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";


import Lightbox from "yet-another-react-lightbox";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Captions from "yet-another-react-lightbox/plugins/captions";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";

const Images = ({ xml, setImagesCount = null }) => {

	const [index, setIndex] = React.useState(-1);
	const [imageCategor, setImageCategor] = useState(null)

	const [images, setImages] = useState(null)

	useEffect(() => {
		const parser = new XMLParser();
		try {
			let jsonObj = parser.parse(xml)
			setImages(jsonObj.data);
		}
		catch (e) {
			console.log(e)
		}
	}, [xml])

	useEffect(() => {
		if (images) {
			setImagesCount(images.categors.map(categor => categor.picture.length))
		}


	}, [images])

	const showImage = (key, index) => {
		setIndex(index);
		setImageCategor(key);
	}
	return (
		<>
			<Accordion>
				{images && images.categors && images.categors.map((categor, key) => <Accordion.Item onSelect={() => alert(1)} key={key} eventKey={key}>
					<Accordion.Header>{categor.name} ({categor.picture.length} db kép)</Accordion.Header>
					<Accordion.Body style={{ display: "flex", flexWrap: "wrap" }}>

						{categor.picture.map((img, index) =>
							<Card key={img.id} style={{ margin: "10px", width: "23%" }}>
								<LazyLoadImage onClick={() => showImage(key, index)}
									src={img.thumb_url}
									alt={`${img.title} című kép`}
								/>

								<Card.Body>
									<Card.Title>{img.title}</Card.Title>
									<Card.Subtitle>{img.author.full_name}</Card.Subtitle>
								</Card.Body>
							</Card>
						)}
						<Lightbox
							open={imageCategor === key && index >= 0}
							close={() => { setIndex(-1); setImageCategor(null) }}
							index={index}
							plugins={[Captions, Fullscreen, Zoom]}
							slides={categor.picture.map(img => {
								return {
									src: img.url,
									title: img.title
								}
							})}
						/>
					</Accordion.Body>
				</Accordion.Item>
				)}
			</Accordion>
		</>
	)
};

export default Images;