import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import getCategorAllRates from "../../../actions/getCategorAllRates";
import CSVExport from "./CSVExport";

const ClosedResult = ({ categories, voteID, xml, name }) => {

	const [rates, setRates] = useState([]);
	const loadRates = async () => {
		if (!categories) return;

		let categorKey;
		const ratesList = [];
		for (categorKey in categories) {
			const categorRate = await getCategorAllRates(voteID, parseInt(categorKey) + 1, "3F32sdf2sdf23")
			ratesList.push(categorRate);
		}
		setRates(ratesList);

	}
	useEffect(() => {
		loadRates();

	}, [voteID, categories])

	return (
		<div className=" pb-4 mb-4">
			<h2>A zsűrizés lezárult!</h2>
			<h3>Eredmények megtekitése:</h3>
			{categories.map((categor, key) =>
				<Link
					key={`categor-${key}`}
					to={`/vote/result/${voteID}/${key}`}
					className="btn btn-primary me-3">
					{categor}
				</Link>)}
			{xml && <CSVExport xml={xml} rates={rates} name={name} voteID={voteID} />}
		</div>
	)
};

export default ClosedResult;