import axios from "axios";
import { toast } from "react-toastify";
import fetchHeaders from "./fetchHeaders";

const closeVoteCategories = async (userID, voteID, categorKey) => {
	const formData = new FormData();

	formData.append('UID', userID);
	formData.append('VID', voteID);
	formData.append('categories', categorKey);

	const requestOptions = {
		headers: fetchHeaders("multipart/form-data"),
	};
	const response = await axios.post(`${process.env.REACT_APP_API_HOST}/api/vote/cv`, formData, requestOptions);
	if (response.status === 404) {
		alert("Hiba a mentés során");
	}
	if (response.status === 500) {
		alert("Hiba a mentés során!");
	}
	if (response.status !== 200) {
		return false;
	}
	const data = await response.data
	toast.info(data.message);
	return true;
};

export default closeVoteCategories;