import axios from "axios";
import fetchHeaders from "./fetchHeaders";

const deleteField = async (voteID, fieldID) => {

	const response = await axios.delete(
		`${process.env.REACT_APP_API_HOST}/api/vote/fieldDelete/${voteID}/${fieldID}`,
		{
			headers: fetchHeaders()
		});
	if (response.status === 404) {
		alert("Hiba a törlés során");
		return false;
	}
	if (response.status === 500) {
		alert("Hiba a törlés során!");
		return false;
	}
	if (response.status !== 200) {
		return false;
	}
	return true;
};

export default deleteField;