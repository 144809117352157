import React, { useEffect, useState } from "react";
import { Button, Container, Navbar } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import getVoteResult from "../../../actions/getVoteResult";
import LinkHeader from "../../LinkHeader";

const ShowResult = () => {

	let { voteID, categorKey, hash } = useParams();
	if (!voteID || !categorKey) {
		window.location = "/";
	}

	const userHash = hash || "49c7bbd98e9ae891d0bd93cfc7d400c4";

	const [result, setResult] = useState(null);

	const getResult = async () => {
		const content = await getVoteResult(voteID, categorKey, userHash);
		setResult(content)
	}
	useEffect(() => {
		getResult();
	}, [])
	const navigate = useNavigate();
	const goBack = () => {
		navigate(-1);
	}

	return (
		<>
			{result &&
				<div className="container">
					<Navbar bg="light" expand="lg" className={"simple"}>
						<Container>
							<Button onClick={goBack}> &#8592; Vissza</Button>
						</Container>
					</Navbar>
					<div dangerouslySetInnerHTML={{ __html: result }}></div>
				</div>}
		</>
	)
};

export default ShowResult;