import { configureStore } from '@reduxjs/toolkit'
import userSlice from './reducers/userSlice'
import voteSlice from './reducers/voteSlice'
import jurySlice from './reducers/onlineJurySlice'
import votingRules from './reducers/votingRules'
import awardsSlice from './reducers/awardsSlice'

export default configureStore({
	reducer: {
		user: userSlice,
		vote: voteSlice,
		jury: jurySlice,
		rules: votingRules,
		awards: awardsSlice
	},
})