import axios from "axios";
import fetchHeaders from "./fetchHeaders";

const getCategorAllRates = async (voteID, selectedCategorKey, hash) => {
	const response = await axios.get(`${process.env.REACT_APP_API_HOST}/api/vote/v/av/${voteID}/${selectedCategorKey}/${hash}`,
		{
			headers: fetchHeaders()
		});
	if (response.status !== 200 || response.status === 404) {
		console.log("no rates");
		return null;
	}

	const text = await response.data;
	return text.data;

}

export default getCategorAllRates;