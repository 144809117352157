const { XMLParser } = require('fast-xml-parser');

const keyValidate = (object, xmlKeys, equalLength = true) => {
	const objectKeys = Object.keys(object)
	if (equalLength && objectKeys.length !== xmlKeys.length) {
		return false;
	}
	for (let i = 0; i < xmlKeys.length; i++) {
		if (!objectKeys.includes(xmlKeys[i])) {
			return false;
		}
	}
	return true;
}

const xmlValidate = (xml, setValidateXML, setXMLError, setCategories) => {
	const categors = [];
	var reader = new FileReader();

	reader.onload = function (e) {
		var content = reader.result;
		const parser = new XMLParser();
		try {
			let jsonObj = parser.parse(content);
			if (!keyValidate(jsonObj, ["?xml", "data"])) {
				setXMLError("Hibás xml, nincs adat benne")
				setValidateXML(false);
				return false;
			}
			if (!keyValidate(jsonObj["data"], ["categors"])) {
				setXMLError("Hibás xml, nincsenek kategóriák")
				setValidateXML(false);
				return false;
			}
			for (let categor of jsonObj["data"]["categors"]) {
				categors.push(categor.name);
				//const categor = jsonObj["data"]["categors"][c];
				if (!keyValidate(categor, ["name", "picture"])) {
					setXMLError("Hibás xml, hiányzó kategóiranév vagy nincsenek képek valamelyik kategóriában.")
					setValidateXML(false);
					return false;
				}
				for (let pic of categor.picture) {

					if (!keyValidate(pic, ["author", "thumb_url", "title", "url"], false)) {
						setXMLError("Hibás xml, hiányos kép tulajdonságok. (author, thumb_url, title, url)")
						setValidateXML(false);
						return false;
					}
					if (!keyValidate(pic.author, ["full_name"], false)) {
						setXMLError("Hibás xml, nincs megadva a kép készítőjének a teljes neve.");
						setValidateXML(false);
						return false;
					}
				}
			};
			setValidateXML(true);
			setXMLError("");
			setCategories(categors);
			return true;
		}
		catch (e) {
			setXMLError("Szintaxis hiba az xml-ben")
			setValidateXML(false);
			return false;
		}
	}

	reader.readAsText(xml);
}

export default xmlValidate;
