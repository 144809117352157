export const imageOrderAndLimit = (rates, limit, reverse = false) => {
	const rateCount = {};
	rates.map((rate) => {
		let maxRate = rateCount[rate.image] || 0;
		maxRate += parseInt(rate.rate);
		rateCount[rate.image] = maxRate;

	})
	const rateCountList = [];
	let imgKey;
	for (imgKey in rateCount) {
		rateCountList.push({ imgKey: imgKey, count: rateCount[imgKey] });
	}
	rateCountList.sort((a, b) => b.count - a.count);

	const limitedRates = [];
	let rate;
	for (rate of rateCountList) {
		if (limit === 0 || limitedRates.length < limit || limitedRates.at(-1).count === rate.count) {
			limitedRates.push(rate);
		}
		else {
			break;
		}
	}
	if (reverse) {
		limitedRates.sort((a, b) => a.count - b.count);
	}
	return limitedRates;
}