import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { Button, ListGroup, ListGroupItem } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import fetchHeaders from "../../actions/fetchHeaders";

const ListVoting = () => {
	let { id } = useParams();
	const [voteList, setVoteList] = useState(null);
	useEffect(() => {
		axios.get(`${process.env.REACT_APP_API_HOST}/api/vote/u/${id}`,
			{
				headers: fetchHeaders()
			})
			.then(response => {
				if (response.status === 200) {
					return response.data;
				}
				window.location = "/"
				return false;
			})
			.then(data => setVoteList(data.data));

	}, []);
	return (
		<ListGroup>
			{voteList && voteList.map(elem => <ListGroupItem>{elem.user_name} <Link to={`/r/${elem.hash}`}><Button> real voting</Button></Link></ListGroupItem>)}
		</ListGroup>
	)
};

export default ListVoting;