
import {
  Route,
  HashRouter,
  Routes,
} from "react-router-dom";

import FrontPage from './Components/FrontPage';
import Header from "./Components/Header/Header";
import ListVoting from "./Components/RealTime/ListVoting";
import NewVote from "./Components/NewVote/NewVote";
import RealTimeStart from "./Components/RealTime/RealTimeStart";
import RealTimeVoting from "./Components/RealTime/RealTimeVoting";
import Resut from "./Components/RealTime/Results";
import ShowResult from "./Components/RealTime/Result/ShowResult";
import Test from "./Components/Test/Test";
import VotePage from "./Components/Vote/VotePage";
import Voting from "./Components/Vote/Voting";

import Login from "./Login-reg/Login";
import Registration from "./Login-reg/Registration";

import 'bootstrap/dist/css/bootstrap.min.css';
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/styles.css";
import './App.css';


import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { HeaderProvider } from "./contexts/headerContext";


function App() {
  return (<HeaderProvider>
    <ToastContainer />
    <HashRouter>
      <Header />
      <Routes>
        <Route
          path='/test'
          element={<Test />}
        />
        <Route
          path='/new'
          element={<NewVote />}
        />
        <Route
          path='/vote/:id'
          element={<VotePage />}
        />
        <Route
          path='/login'
          element={<Login />}
        />
        <Route
          path='/registration'
          element={<Registration />}
        />
        <Route
          path='/v/:hash'
          element={<Voting />}
        />
        <Route
          path='/r/all/:id'
          element={<ListVoting />}
        />
        {/* A hármat egy componens alá kell vinni */}
        <Route path='/r/:identify'
          element={<RealTimeStart />}
        />
        <Route path='/r/s/*'
          element={<RealTimeVoting />}
        />
        <Route path='vote/result/:voteID/:categorKey'
          element={<ShowResult />}
        />
        <Route path='vote/result/:voteID/:categorKey/:hash'
          element={<ShowResult />}
        />
        <Route path='vote/:voteID/result/:hash'
          element={<Resut />}
        />

        <Route path='vote/:voteID/result'
          element={<Resut />}
        />
        {/*       <Route path='/r/:id/:voteID?'
        element={<RealTimeStart />}
      /> */}
        {/*       <Route
        path='/r/:hash'
        element={<RealVoting />}
      />
      <Route
        path='/r/:hash/start'
        element={<RealVoting />}
      />
      <Route
        path='/r/:hash/:categorKey'
        element={<RealVoting />}
      /> */}
        {/* A hármat egy componens alá kell vinni lehet az elöbbi harommal együtt */}
        {/*      <Route
        path='/r/a/:voteID'
        element={<RealVotingAdmin isStart={false} />}
      />
      <Route
        path='/r/a/:voteID/start'
        element={<RealVotingAdmin isStart={true} />}
      />
      <Route
        path='/r/a/:voteID/:categorKey'
        element={<RealVotingAdmin isStart={true} />}
      /> */}
        <Route
          path='/'
          element={<FrontPage />}
        />
      </Routes>
    </HashRouter>
    </HeaderProvider>
)}

export default App;
