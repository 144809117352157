import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import changeRealTime from "../../actions/changeRealTime";
import deleteField from "../../actions/deleteField";
import fetchHeaders from "../../actions/fetchHeaders";
import saveField from "../../actions/saveField";
import AwardField from "./Fields/AwardField";
import LimitField from "./Fields/LimitField";

const RealTimeFields = ({ vote, setVote, closed }) => {
	const [fields, setFields] = useState({ limit: [], award: [], custom: [] });

	useEffect(() => {
		axios.get(`${process.env.REACT_APP_API_HOST}/api/vote/plus/${vote.id}/f2s5zh43ldk32K2kj234K`,
			{
				headers: fetchHeaders()
			})
			.then(response => {
				if (response.status === 200) {
					return response.data;
				}
				alert("Hiba az egyedi mezők betöltése során. Póbáld meg késöbb")
			})
			.then(data => {
				const fieldList = data.data;
				if (fieldList) {
					setFields({
						limit: fieldList.filter(field => field.type === "limit"),
						award: fieldList.filter(field => field.type === "award"),
						custom: fieldList.filter(field => field.type === "custom")
					})
				}

			});
	}, [vote])

	const updateCheck = () => {
		const checked = !vote.realtime;
		changeRealTime(vote.id, checked);
		///upRT
		setVote({ ...vote, realtime: checked });
	}

	const addField = (title = "", categor = "all", type = "custom") => {
		const newFields = { ...fields };
		newFields[type] = [...newFields[type], { title: title, categor: categor, type: type, value: "", isNew: true }]
		setFields(newFields)
	}

	const deleteFieldItem = async (key, type) => {
		const selectedField = fields[type][key];
		if (window.confirm("Biztos, hogy törlöd ezt a mezőt?")) {
			if (selectedField.id) {
				if (await deleteField(vote.id, selectedField.id)) {
					const newFields = { ...fields };
					newFields[type] = fields[type].filter((e, k) => k !== key);
					setFields(newFields);
					toast.info("Mező törölve!");
				};

			}
			else {
				const newFields = { ...fields };
				newFields[type] = fields[type].filter((e, k) => k !== key);
				setFields(newFields);
				toast.info("Mező törölve!");
			}
		}
	}

	const saveFieldFn = async (field, key, type) => {
		const response = await saveField(vote.id, field);
		if (response !== false) {
			toast.info("A mező mentve");
			const changedType = fields[type];
			field.id = response.data.id;
			changedType[key] = field;

			const newFields = { ...fields };
			newFields[type] = changedType;
			setFields(newFields);
		}
	}

	return (<>
		<hr />
		<h5>Valóidejű zsűrizés</h5>
		<Form.Check
			type="checkbox"
			id="realtimeEnabled"
			label={`A valósidejű zsűrizés ${vote.realtime ? "(engedélyezve)" : "(kikapcsolva)"}`}
			defaultChecked={vote.realtime}
			disabled={closed}
			onClick={!closed && updateCheck}
		/>
		{vote.realtime && <div className="well">
			<h6>Részletes beállítások</h6>
			<div>
				<div><b>Mező hozzáadása:</b></div>
				<Button variant="outline-secondary" size="sm" onClick={() => addField("Limit", "all", "limit")} title="Limit mező hozzáadása" aria-label="Limit mező hozzáadása">Limit mező</Button>
				{["Első helyezet", "Második helyezet", "Harmadik helyezet", "Különdíj", "Saját"]
					.map(title =>
						<Button className="ms-2" key={title} title={`${title} mező hozzáadása`} aria-label={`${title} mező hozzáadása`} variant="outline-secondary" size="sm" onClick={() => addField(title, "all", "award")}>{title} díj mező</Button>
					)}
			</div>
			{fields && fields.limit.length > 0 && <div className="limits">
				<hr />
				<h6>Limitek</h6>
				<div>
					Ezt a mezőt akkor érdemes használni ha a valósidejű zsűrizés esetén pl. a továbbjutás, kiállítás vagy bármilyen más féltétel alapján szeretnénk limitálni a tovább jutók számát.
				</div>
				{fields.limit.map((field, fieldKey) =>
					<LimitField
						key={fieldKey}
						fieldKey={fieldKey}
						field={field}
						closed={closed}
						categors={JSON.parse(vote.categors)}
						deleteHandler={!closed && deleteFieldItem}
						saveHandler={!closed && saveFieldFn}
					/>
				)}
			</div>
			}
			{fields && fields.award.length > 0 && <div className="award">
				<hr />
				<h6>Kiosztható díjak</h6>
				<div>
					{fields.award.map((field, fieldKey) => <AwardField
						key={fieldKey}
						fieldKey={fieldKey}
						field={field}
						closed={closed}
						categors={JSON.parse(vote.categors)}
						deleteHandler={!closed && deleteFieldItem}
						saveHandler={!closed && saveFieldFn}
					/>
					)}
				</div>
			</div>}
		</div>}
	</>)
};

export default RealTimeFields;