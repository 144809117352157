import { Container, Dropdown, DropdownButton } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import VoteList from './Vote/VoteList';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const FrontPage = () => {
	cookies.remove('hash');
	return (
		<div className="App">
			<Container>
				<VoteList />
			</Container>
			<DropdownButton
				className="float bottom right circle-icon"
				key={"up"}
				id={`dropdown-button-drop-uo`}
				drop={"up"}
				variant="primary"
				title={`+`}
			>
				<Dropdown.Item eventKey="1"><Link to="/new">Új zsűrizés létrehozása</Link></Dropdown.Item>
			</DropdownButton>
		</div>
	);
}

export default FrontPage;
