import React, { useEffect, useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";

const LimitField = ({ field, categors, deleteHandler, saveHandler, fieldKey, closed }) => {
	const [selected, setSelected] = useState(false);
	const [limit, setLimit] = useState(field?.limit ?? 0);
	const [categor, setCategor] = useState(field?.categor ?? -1);

	useEffect(() => {
		if (field) {
			setLimit(field.values);
			setCategor(field.categor);
			if (field.isNew && !field.id) {
				setSelected(true)
			}
			else {
				setSelected(false);
			}
		}
	}, [field]);

	const closeSelect = () => {
		if (selected || window.confirm("Biztos befejezed ezt a mező szerkesztését mentés nélkül?") == true) {
			setSelected(false);
			setLimit(field.values);
			setCategor(field.categor);
		}
	}

	const deleteFn = () => {
		deleteHandler(fieldKey, field.type);
	}
	const saveFn = () => {
		const value = { ...field, values: limit, categor: categor };
		saveHandler(value, fieldKey, field.type);
		setSelected(false);
	}


	return (
		<>
			<InputGroup className="mb-3">
				<InputGroup.Text id="inputGroup-sizing-default">
					Limit
				</InputGroup.Text>
				<Form.Control
					disabled={!selected}
					aria-label="Típus"
					onChange={(e) => setLimit(e.target.value,)}
					value={limit}
				/>
				<InputGroup.Text id="inputGroup-sizing-default">
					Limit kategória
				</InputGroup.Text>
				<Form.Select
					disabled={!selected}
					aria-label="Kategoria"
					onChange={(e) => setCategor(e.target.value)}
					value={categor}
				>
					<option value="all">Összes</option>
					{categors && categors.map((categor, key) => <option key={categor + key} value={key}>{categor}</option>)}
				</Form.Select>
				{!closed && <>
					{selected && <Button variant="outline-secondary" onClick={saveFn} >Mentés</Button>}
					{selected && <Button variant="outline-secondary" onClick={closeSelect}>Mégsem</Button>}
					{!selected && <Button variant="outline-danger" onClick={deleteFn}>Törlés</Button>}
					{!selected && <Button variant="outline-danger" onClick={() => setSelected(true)}>Szerkesztés</Button>}
				</>}
			</InputGroup>
		</>
	)
};

export default LimitField;