import axios from "axios";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";
const cookies = new Cookies();

const getNewTokenByHash = async (hash) => {
	try {
		const response = await axios.get(`${process.env.REACT_APP_API_HOST}/api/user/h/${hash}`);
		if (response.status !== 200 || response.status === 404) {
			toast.error("Nem létező zsürizés.")
			cookies.remove("hash");
			window.location = "/";
			return null;
		}

		const text = await response.data;
		cookies.set('token', text.data.token, { path: '/', sameSite: "strict" });
		return true;
	}
	catch (e) {
		alert("Nem létező zsürizés.")
		cookies.remove("hash");
		window.location = "/";
		return null;
	}

}

export default getNewTokenByHash;