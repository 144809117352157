import axios from "axios";
import fetchHeaders from "./fetchHeaders";

const getVoteCategoriesStatus = async (voteID, userID) => {
	const response = await axios.get(`${process.env.REACT_APP_API_HOST}/api/vote/v/vs/${voteID}/${userID}`,
		{
			headers: fetchHeaders()
		});
	if (response.status !== 200 || response.status === 404) {
		console.log("no categoreis");
		return null;
	}

	const text = await response.data;
	return text.data.map((item ) => Number(item) || null);

}

export default getVoteCategoriesStatus;