import socketIOClient from "socket.io-client";
import { toast } from 'react-toastify';

const socket = socketIOClient(`https://room.palyazatkezelo.com:30002`, {
	/* autoConnect: false,
	reconnection: true,
	reconnectionDelay: 1000,
	reconnectionDelayMax: 5000,
	reconnectionAttempts: Infinity */
	//secure: true,
	//withCredentials: true,
	reconnectionAttempts: 3
});

socket.on("session", ({ sessionID, userID }) => {
	socket.auth = { sessionID };
	localStorage.setItem("sessionID", sessionID);
	socket.userID = userID;
});

if (!socket._callbacks["$connectUser"]) {
	socket.on("connectUser", (name) => {
		toast.info(`${name} belépett`);
	})
}
if (!socket._callbacks["$disconnectUser"]) {
	socket.on("disconnectUser", (name) => {
		toast.info(`${name} kilépett`);
	})
}




export default socket;