export const resultGurad = async (vote, categories, alert = false, redirect = true) => {

	if (vote.state && vote.stat.length === categories.length) {
		if (alert) {
			alert("Ez a zsűrizés már levan zárva. Most átirányítjuk az eredmények oldalra.")
		}
		if (redirect) {
			window.location = `#/vote/result/${vote.id}`;
		}
		return true;
	}
	return false;
}

export default resultGurad;
