import React, { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Button, Card } from "react-bootstrap";
import Lightbox from "yet-another-react-lightbox";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Captions from "yet-another-react-lightbox/plugins/captions";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails"
import "yet-another-react-lightbox/plugins/thumbnails.css";

//simport StarRatings from "react-star-ratings";

import "./Finished.scss";
import StarRate from "../StarRate/StarRate";
import changeRateValue from "../../../actions/changeRateValue";
import { useSelector } from "react-redux";
import closeVoteCategories from "../../../actions/closeVoteCategories";

const Finished = ({ images, rates, selectedCategorKey, voteID, updateRates, finishHandler }) => {
	const userState = useSelector((state) => state.user.value)

	const [imageIndex, setImageIndex] = useState(-1)
	const [sortedImages, setSortedImages] = useState(images);

	useEffect(() => {
		const sortedImagesArray = images.map((image, key) => {
			const currentRate = rates.find(rate => rate.image == image.originalKey)?.rate || null
			return {...image, currentRate: parseInt(currentRate), calculatedKey: key}
		})
		
		sortedImagesArray.sort((a, b) => {
			if (a.currentRate > b.currentRate) return -1;
			if (a.currentRate < b.currentRate) return 1;
			return 0;
		})
		setSortedImages(sortedImagesArray);
	}, [images, rates])

	const updateRate = async (newRate, image) => {
		const rate = rates.find(rate => rate.image == image.originalKey);
		if (!rate) return;

		const response = await changeRateValue(newRate, rate.id, userState.hash, selectedCategorKey-1, voteID);
		if (response) {
			const imgTitle = image.title;
			alert(`A ${imgTitle} című kép pontszáma módosítva!`);
			updateRates()
		}
	}

	const closeCategories = async () => {
		if (window.confirm("Biztosan lezárja a kategória pontozását?")) {
			console.log(userState)
			const isClosed = await closeVoteCategories(userState.id, voteID, selectedCategorKey)
			if (isClosed) {
				alert("Kategória pontozás lezárva!");
				finishHandler();
			}
		}
	
	}

	return(
		<div className="finished">
			<h3>Ebben a kategóriában az összes kép ponzotva lett. A kategória pontozásának lezárása elött ez az utolsó móodsítási lehetőség!</h3>
			<Button className="finished__close" onClick={closeCategories}>Kategória pontozás lezárása</Button>
			<div className="finished__images">
				{sortedImages && sortedImages.map((image, index) => {
					return <Card className="finished__images--card mb-4">
						<LazyLoadImage
							onClick={() => setImageIndex(index)}
							className="finished__images--image"
							src={image.thumb_url}
							alt={`${image.title} című kép`}
						/>
						{/* <Card.Img variant="top" style={{
							backgroundImage: `url("${image.thumb_url}")`
						}} /> */}
						<Card.Body>
							<Card.Title>{image.title}</Card.Title>
							{image.currentRate && <Card.Body>
								<StarRate
									className="star-rating"
									changeRating={(rateValue) => updateRate(rateValue,image)}
									numberOfStars={10}
									rating={image.currentRate}
									starRatedColor="darkorange"
									starDimension={'40px'}
									starSpacing={2}
									name='rating'
									editing={false}
								/>
								<br />
								{image.currentRate} csillagra értékelve</Card.Body>}
						</Card.Body>
					</Card>
				})}
			</div>
		{images && <Lightbox
			open={imageIndex > -1}
			close={() => { setImageIndex(-1) }}
			index={imageIndex}
			plugins={[Captions, Fullscreen, Zoom, Thumbnails]}
			slides={sortedImages.map(img => {
				return {
					src: img.url,
					title: img.title
				}
			})}
		/>}
			
		</div>
	)
}

export default Finished;
