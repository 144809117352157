import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Card, Container, Form, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import fetchHeaders from "../../actions/fetchHeaders";
import xmlValidate from "./xmlValidate";
import { toast } from "react-toastify";


const NewVote = () => {
	const [name, setName] = useState("");
	const [zsuri, setZsuri] = useState([]);
	const [xml, setXml] = useState(null);
	const [validXML, setValidaXML] = useState(false);
	const [xmlError, setXMLError] = useState("");
	const [categories, setCategories] = useState([]);


	const setZsuriValue = (key, value) => {
		let current = [...zsuri];
		current[key] = value;
		setZsuri(current)
	}

	useEffect(() => {
		if (xml) {
			xmlValidate(xml, setValidaXML, setXMLError, setCategories);
		}
	}, [xml])

	const removeZsuri = (key) => {
		let current = zsuri.filter((v, k) => k !== key);
		setZsuri(current)
	}
	const saveForm = () => {
		if (!name) {
			alert("A pályázat címének megadás kötelező");
			return false;
		}
		if (zsuri.length <= 0) {
			alert("A zsűri megadás kötelező");
			return false;
		}
		if (!validXML) {
			alert("XML állomány nincs megadva, vagy hibás! ")
			return false;
		}
		const formData = new FormData();

		formData.append('name', name);
		formData.append('zsuri', JSON.stringify(zsuri));
		formData.append('categories', JSON.stringify(categories));
		formData.append('xml', xml);

		const requestOptions = {
			headers: fetchHeaders("multipart/form-data")
		};
		axios.post(`${process.env.REACT_APP_API_HOST}/api/vote/new`, formData, requestOptions)
			.then(response => {
				if (response.status === 200 || response.status === 201) {
					return response.data;
				}
				if (response.status === 401) {
					window.location = "/#/login"
				}
				//throw new Error('Unathorized');
			})
			.then(data => {
				toast.info(data.messages);
				window.location = "/";
			})
			.catch(e => {
				toast.error("Hiba a mentés során!");
				console.log(e.response)
			});
		return false;
	}
	return (
			<Container>
				<h3>Zsűrizés hozzáadása</h3>
				<Form.Group className="mb-3">
					<Form.Label>Pályázat címe</Form.Label>
					<Form.Control type="text" placeholder="Pályázat címe" required defaultValue={name} onChange={(e) => setName(e.target.value)} />
				</Form.Group>
				<Button onClick={() => setZsuri([...zsuri, { name: "", email: "" }])}>Új zsűri hozzáadása</Button>
				<div className="zsuri-cards">
					{zsuri && zsuri.map((zs, k) => (
						<Card className="zsuri-card">
							<Card.Body>
								<Card.Title>Zsűritag</Card.Title>
								<Card.Text>
									<Form.Group className="mb-3">
										<Form.Label>Zsűritag neve</Form.Label>
										<Form.Control type="text" placeholder="Zsűriztető" required defaultValue={zs.name} onChange={(e) => setZsuriValue(k, { name: e.target.value, email: zs.email })} />
									</Form.Group>
									<Form.Group className="mb-3">
										<Form.Label>Zsűritető email címe</Form.Label>
										<Form.Control type="email" placeholder="Elérhetősége" required defaultValue={zs.email} onChange={(e) => setZsuriValue(k, { name: zs.name, email: e.target.value })} />
									</Form.Group>
								</Card.Text>
								<Button variant="danger" className="zsuri-remove" onClick={() => removeZsuri(k)}>Törlés</Button>
							</Card.Body>
						</Card>
					))}
				</div>
				<div className="xml">
					{xmlError}
					<Form.Group className="mb-3">
						<Form.Label>Képek (xml állomány)</Form.Label>
						<Form.Control type="file" accept=".xml" placeholder="XML állomány" required defaultValue={xml} onChange={(e) => setXml(e.target.files[0] || null)} />
					</Form.Group>
				</div>
				<Button onClick={saveForm}>Mentés</Button>
			</Container>
	);
}

export default NewVote;
