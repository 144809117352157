import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row, Spinner } from "react-bootstrap";
import StarRate from "../StarRate/StarRate";
import ProgressBar from 'react-bootstrap/ProgressBar';
import Lightbox from "yet-another-react-lightbox";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Captions from "yet-another-react-lightbox/plugins/captions";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import fetchHeaders from "../../../actions/fetchHeaders";
import axios from "axios";
import hashSortImages from "./hashSortImages";
import AllImageModal from "../Voting/AllImageModal";
import { toast } from "react-toastify";
import Finished from "./Finished";

const StarRating = ({ selectedCategor, selectedCategorKey, voteID, userID, finishHandler, showAllImage, setShowAllImage, categorStatus }) => {
	const [images, setImages] = useState(null);
	//const [mixedImages, setMixedImages] = useState(null)
	const [rates, setRates] = useState([]);
	const [showModal, setShowModal] = useState(false);
	//const [rating, setRating] = useState(0);
	const [imageKey, setImageKey] = useState(0);
	const [filteredImage, setFilteredImage] = useState([]);
	const [loaded, setLoaded] = useState(false);
	const [finished, setFinished] = useState(null);

	const changeRating = (newRating) => {

		const formData = new FormData();

		formData.append('UID', userID);
		formData.append('categories', selectedCategorKey);
		formData.append('images', imageKey);
		formData.append('VID', voteID);
		formData.append('rate', newRating);

		const requestOptions = {
			headers: fetchHeaders("multipart/form-data")
		};
		//setRating(newRating);
		axios.post(`${process.env.REACT_APP_API_HOST}/api/vote/myvote`, formData, requestOptions)
			.then(response => {
				if (response.status === 200) {
					return response.data;
				}
				if (response.status === 404) {
					alert("Nincs ilyen zsűrizés");
					window.location = "/"
				}
				if (response.status === 500) {
					alert("Hiba a mentés során!");
				}
				return false;
			})
			.then(data => {
				const actualRates = [...rates, data.data];
				setLoaded(false);
				setRates(actualRates);
			});
		/*
				$userID = $this->request->getVar("UID");
		$voteCategor = $this->request->getVar("categories");
		$voteImagesKey = json_decode($this->request->getVar("images"));
		$voteID = $this->request->getFile("VID");
		$voteRate = $this->request->getFile("rate");

		*/
	}

	useEffect(() => {
		if (images) {
			const filtered = [];

			images.filter((img) => {
				for (let rate of rates) {
					if (parseInt(rate.image) === img.originalKey) {
						return false;
					}
				}
				filtered.push({ key: img.originalKey, rate: img })
				return true;
			})
			if (filtered.length === 0) {
				//salert("Ebben a kategóriában már nincs pontozni való!");
				//window.location.reload();
				updateRates();
				setFinished(true);
				//finishHandler();
			}
			else {
				setFinished(false);
				//const randomKey = Math.floor(Math.random() * filtered.length)

				//Random fixed image list by hash
				const next = filtered[0]
				setImageKey(next.key);
				setFilteredImage(next.rate)
			}
		}
	}, [rates])


	const updateRates = () => {
		axios.get(`${process.env.REACT_APP_API_HOST}/api/vote/v/v/${voteID}/${userID}/${selectedCategorKey}`,
		{
			headers: fetchHeaders()
		})
		.then(response => {
			if (response.status === 200) {
				return response.data;
			}
			if (response.status === 404) {
				alert("Nincs ilyen zsűrizés");
			}
			window.location = "/"
			return false;
		})
		.then(data => {
			setRates(data.data)
		});
	}
	useEffect(() => {
		updateRates();
	}, [selectedCategorKey])

	useEffect(() => {
		if (selectedCategor) {
			setImages(hashSortImages(selectedCategor.picture));
			//setMixedImages(hashSortImages(selectedCategor.picture))
		}
	}, [selectedCategor])
	const ratePosition = (images && Math.round(rates.length / (images.length / 100))) || 0;

	return (<>
	{finished===false && <div>
		{filteredImage && filteredImage.url && <>
			<Row>
				<Col>
					<ProgressBar now={ratePosition} label={`${ratePosition}% (${rates.length}/${images.length})`} />
				</Col>
				<Col md="auto">
					<Button size="sm" variant="info" onClick={() => setShowAllImage(true)}>Képek áttekintése</Button>
				</Col>
			</Row>
			<Card style={{ height: "calc(90vh - 94px)", display: "flex", alignItems: "center", textAlign: "center" }}>
				<Card.Img style={{ height: "100%", opacity: (!loaded ? 0 : 1), maxHeight: "100%", width: "max-content", borderRadius: "0" }} src={filteredImage.url + "?" + imageKey} onClick={() => { setShowModal(true) }} onError={({ currentTarget }) => {
					setLoaded(true)
					toast.error("A kép nem tölthető be!")
				}} onLoad={() => setLoaded(true)} />
				{loaded && <Card.Body>
					<Card.Title>{filteredImage.title}</Card.Title>
					<StarRate
						className="star-rating"
						changeRating={changeRating}
						numberOfStars={10}
					/>
				</Card.Body>}
				{!loaded && <Card.Body style={{ height: "100%" }}><Spinner animation="border" variant="secondary" /> <br />Kis türelem, a kép betöltés folyamatban</Card.Body>}
			</Card>

			<Lightbox
				open={showModal}
				index={0}
				close={() => { setShowModal(false) }}
				plugins={[Captions, Fullscreen, Zoom]}
				slides={[
					{
						src: filteredImage.url,
						title: filteredImage.title
					}]
				}
			/>
		</>}
		{images && showAllImage && <AllImageModal show={showAllImage} setShow={setShowAllImage} images={images} rates={rates} />}
	</div>}
	{finished && <Finished 
		images={images} 
		rates={rates} 
		selectedCategorKey={selectedCategorKey}
		voteID={voteID}
		userID={userID}
		updateRates={updateRates}
		finishHandler={finishHandler}
		/>}
	</>
	)
};

export default StarRating;