import React from "react";
import { Button, Col, ListGroup } from "react-bootstrap";
import { Person, PersonFill, PersonFillGear } from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import { setCategories } from "../../redux/reducers/voteSlice";

const UserList = () => {
	const userState = useSelector((state) => state.user.value)
	const juryState = useSelector((state) => state.jury.value);
	const isEmpty = (object) => Object.keys(object).length === 0

	const dispatch = useDispatch()


	if (isEmpty(userState)) {
		alert("Hiba")
	}

	const backToCategories = () => {
		dispatch(setCategories(null));
	}
	return (
		<Col xs="2">
			{userState.icon === "admin" && <Button size="sm" onClick={backToCategories} className="mt-2 mb-2" variant="outline-secondary">&#8810; Vissza a kategóriákhoz</Button>}
			<ListGroup variant="flush">
				<ListGroup.Item>Belépett zsűritagok</ListGroup.Item>
				{juryState.filter(u => u.uid === userState.hash).map(user => <ListGroup.Item variant="success" key={user.username}>{user.icon === "admin" ? <PersonFillGear /> : <PersonFill />} {user.username}</ListGroup.Item>
				)}
				{juryState.filter(u => u.uid !== userState.hash).map(user => <ListGroup.Item key={user.username}>{user.icon === "admin" ? <PersonFillGear /> : <Person />} {user.username}</ListGroup.Item>
				)}
			</ListGroup >
		</Col>
	)
};

export default UserList;