import React from "react";
import "./StarRate.scss";

import { Star } from "../../../Icons";

const StarRate = ({ changeRating,  numberOfStars = 10, starDimension="35px", starSpacing=3, rating=null }) => {
	const [starHover, setStarHover] = React.useState(0);
	return (
		<div 
			className="star-rate"
			onMouseLeave={() => setStarHover(0)}
			onClick={() => changeRating(starHover)}
			style={{ gap: starSpacing }}
			>
			{ Array.from({ length: numberOfStars }).map((_, i) => (
				<div
					className={"star-rate__star " + (i < starHover ? "star-rate__star--active" : "") + (rating && i < rating ? " star-rate__star--rated" : "")}
					key={i}
					onMouseEnter={() => setStarHover(i + 1)}
					title={i + 1}
					style={{ width: starDimension, height: starDimension}}
				>
					<Star />
					<span className="star-rate__number">{i + 1}</span>
				</div>
			))}
		</div>
	)
};

export default StarRate;