import axios from "../actions/axiosConfig";
import React, { useState } from "react";
import { Button, Card, Container, Form } from "react-bootstrap";
import { toast } from "react-toastify";

const Registration = () => {

	const [email, setEmail] = useState(null);
	const [pass, setPass] = useState(null);
	const [passAgain, setPassAgain] = useState(null)
	const [fullName, setFullName] = useState(null)

	const [emailOk, setEmailOk] = useState(null);
	const [passOk, setPassOk] = useState(null);
	const [fullNameOk, setFullNAmeOk] = useState(null);


	const handleKeyPress = (event) => {
		if (event.key === 'Enter') {
			validate();
		}
	}
	const mailRegexp = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
	const passRegexp = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/;
	const validate = () => {
		let valid = true;
		if (!email) {
			valid = false;
			setEmailOk("Email megadása kötelező!");
		}
		else if (!mailRegexp.test(email)) {
			setEmailOk("Nem valós emailcím.")
		}
		else {
			setEmailOk(null);
		}
		if (!pass) {
			valid = false;
			setPassOk("Jelszó megadása kötelező")
		}
		else if (!passRegexp.test(pass)) {
			valid = false;
			setPassOk("A jelszónak minimum 6 karakter hosszúnak kell lennie és tartalmaznia kell kis és nagy betűt és egy számot!")
		}
		else {
			if (passAgain && passAgain === pass) {
				setPassOk(null)
			}
			else {
				valid = false;
				setPassOk("A két jelszó nem eggyezik!")
			}

		}
		if (!fullName) {
			valid = false;
			setFullNAmeOk("A név megadása kötelező")
		}
		else if (fullName.length < 5) {
			valid = false;
			setFullNAmeOk("A név nem lehet kevesebb mint 5 karakter")
		}
		else {
			setFullNAmeOk(null)
		}
		if (!valid) {
			return false;
		}
		const formData = new FormData();

		formData.append('name', fullName)
		formData.append('email', email);
		formData.append('password', pass);
		formData.append('passwordagain', passAgain);

		axios.post(`${process.env.REACT_APP_API_HOST}/api/user/registration`, formData)
			.then(data => {
				if (data.data.status !== 200) {
					alert(data.data.messages);
					//throw new Error(data.messages);
					return false;
				}
				else {
					toast.info("Sikeres regisztráció")
					window.location = "#/login";
				}
			})

	}
	return (
		<Container>
			<div className="d-flex justify-content-center h-100">
				<Card>
					<Card.Header>
						<h3>Felhasználó létrehozása</h3>
					</Card.Header>
					<Card.Body>
						<Form.Group>
							<Form.Label>Teljes név</Form.Label>
							<Form.Control type="text" className={"form-control" + (fullNameOk === false ? " form-error" : "")} onChange={(e) => setFullName(e.target.value)} placeholder="Teljes név" required onKeyPress={handleKeyPress} />
							{fullNameOk !== null && <Form.Text className="form-error">
								{fullNameOk}
							</Form.Text>}
						</Form.Group>
						<Form.Group>
							<Form.Label>Email cím</Form.Label>
							<Form.Control type="email" className={"form-control" + (emailOk === false ? " form-error" : "")} onChange={(e) => setEmail(e.target.value)} placeholder="Email cím" required onKeyPress={handleKeyPress} />
							{emailOk !== null && <Form.Text className="form-error">
								{emailOk}
							</Form.Text>}
						</Form.Group>
						<Form.Group>
							<Form.Label>Jelszó</Form.Label>
							<Form.Control type="password" className={"form-control" + (passOk === false ? " form-error" : "")} onChange={(e) => setPass(e.target.value)} placeholder="Jelszó" required onKeyPress={handleKeyPress} />
							{passOk !== null && <Form.Text className="form-error">
								{passOk}
							</Form.Text>}
						</Form.Group>
						<Form.Group>
							<Form.Label>Jelszó mégegyszer</Form.Label>
							<Form.Control type="password" className={"form-control" + (passOk === false ? " form-error" : "")} onChange={(e) => setPassAgain(e.target.value)} placeholder="Jelszó ismét" required onKeyPress={handleKeyPress} />
						</Form.Group>
						<br />
						<Button variant="primary" onClick={validate}>Mentés</Button>
					</Card.Body>
				</Card>
			</div>
		</Container>
	)
};

export default Registration;