import axios from "axios";
import fetchHeaders from "./fetchHeaders";

const setAward = async (voteID, award) => {

	const formData = new FormData();

	formData.append('VID', voteID);
	if (award.id) {
		formData.append('awardID', award.id);
	};
	formData.append('value', award.value);
	formData.append('FID', award.field_id);
	formData.append('categor', award.categorKey);
	formData.append('HASH', award.hash);

	const requestOptions = {
		headers: fetchHeaders("multipart/form-data")
	};
	const response = await axios.post(`${process.env.REACT_APP_API_HOST}/api/vote/award`, formData, requestOptions);
	if (response.status === 404) {
		alert("Hiba a mentés során");
	}
	if (response.status === 500) {
		alert("Hiba a mentés során!");
	}
	if (response.status !== 200) {
		return false;
	}
	const data = await response.data
	return data.data;
};

export default setAward;