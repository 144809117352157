import axios from "../actions/axiosConfig";
import React, { useState } from "react";
import { Button, Card, Container, Form } from "react-bootstrap";
import Cookies from "universal-cookie";
import fetchHeaders from "../actions/fetchHeaders";

const Login = () => {

	const [email, setEmail] = useState(null);
	const [pass, setPass] = useState(null);

	const [emailOk, setEmailOk] = useState(null);
	const [passOk, setPassOk] = useState(null);


	const handleKeyPress = (event) => {
		if (event.key === 'Enter') {
			login();
		}
	}
	const login = () => {
		if (!email) {
			setEmailOk(false);
		}
		else {
			setEmailOk(true);
		}
		if (!pass) {
			setPassOk(false)
		}
		else {
			setPassOk(true)
		}
		if (!email || !pass) {
			return false;
		}
		const formData = new FormData();

		formData.append('email', email);
		formData.append('password', pass);

		axios.post(`${process.env.REACT_APP_API_HOST}/api/user/login`,
			formData, {
			headers: {
				//'Content-Type': 'application/json'
			},
			//XMLHttpRequest: true
		}
		)
			.then(data => {
				if (data.status !== 200) {
					alert(data.messages);
					//throw new Error(data.messages);
					return false;
				}
				else {
					const cookies = new Cookies();
					cookies.set('token', data.data.data.token, { path: '/', sameSite: "strict" });
					window.location = "/";
				}
			})

	}
	return (
		<Container>
			<div className="d-flex justify-content-center h-100">
				<Card>
					<Card.Header>
						<h3>Bejelentkezés</h3>
					</Card.Header>
					<Card.Body>
						<Form.Group>
							<Form.Label>Email cím</Form.Label>
							<Form.Control type="text" className={"form-control" + (emailOk === false ? " form-error" : "")} onChange={(e) => setEmail(e.target.value)} placeholder="Email cím" required onKeyPress={handleKeyPress} />
							{emailOk === false && <Form.Text className="form-error">
								Email megadása kötelező.
							</Form.Text>}
						</Form.Group>
						<Form.Group>
							<Form.Label>Jelszó</Form.Label>
							<Form.Control type="password" className={"form-control" + (passOk === false ? " form-error" : "")} onChange={(e) => setPass(e.target.value)} placeholder="Jelszó" required onKeyPress={handleKeyPress} />
							{passOk === false && <Form.Text className="form-error">
								Jelszó megadása kötelező.
							</Form.Text>}
						</Form.Group>
						<br />
						<Button variant="primary" onClick={login}>Belépés</Button>
					</Card.Body>
					<Card.Footer>
						<div className="d-flex justify-content-center links">
							Még nem regisztráltál?<a href="#/registration">Regisztráció</a>
						</div>
						<div className="d-flex justify-content-center">
							<a href="/#">Elfeljetett jelszó?</a>
						</div>
					</Card.Footer>
				</Card>
			</div>
		</Container>
	)
};

export default Login;