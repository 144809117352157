import axios from "axios";
import fetchHeaders from "./fetchHeaders";

const changeRealTime = async (voteID, type) => {

	const response = await axios.get(`${process.env.REACT_APP_API_HOST}/api/vote/upRT/${voteID}/${type ? "enabled" : "disabled"}`, {
		headers: fetchHeaders(),
	});
	if (response.status === 404) {
		alert("Hiba a mentés során");
	}
	if (response.status === 500) {
		alert("Hiba a mentés során!");
	}
	if (response.status !== 200) {
		return false;
	}

	return true;
};

export default changeRealTime;